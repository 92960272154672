import {makeStyles} from "tss-react/mui";

export const usePatientListSearchBoxStyle = makeStyles()((theme) => ({
    main: {
        borderRadius: 25,
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(2)
    },
    textField: {
        width: "100%",
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            alignSelf: "center"
        },
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        borderRadius: 20,
    },
    gridItem: {
        alignSelf: "center",
    },
    gridContainer: {
        alignItems: "stretch-between",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(1),
    }
}));

export const usePatientListStyle = makeStyles()((theme) => ({
    main: {
        borderRadius: 25,
        marginTop: theme.spacing(2),
    }
}));
    