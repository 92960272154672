import {makeStyles} from "tss-react/mui";
import {OnChangeTexFieldPayload} from "../../types/OnChangeTextFieldPayload";
import {memo, useEffect, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import "./richTextField.css";
import ReactQuill, {Quill} from "react-quill";
import clsx from "clsx";
import {Property} from "../../constants/Property";
import {Typography} from "@mui/material";

const COLORS = [
    "#FFC1C1",
    "#FFB86D",
    "#FFEA8D",
    "#D5FF9D",
    "#7CDC00",
    "#8AFFEC",
    "#87D5FF",
    "#D6D3FF",
    "#D0D0D0",
    "#ffb098",
    "#ffa3ff",
    "#E2C174",
    "#00E23B",
    "#FFFFFF",
];

const BACKGROUND_COLORS = [
    "#ffc1c1",
    "#a46820",
    "#83761d",
    "#588127",
    "#158800",
    "#008475",
    "#1f7ea4",
    "#737198",
    "#D0D0D0",
    "#a8624d",
    "#a954ab",
    "#8d732a",
    "#008a00",
    "#505050"
];

const useStyles = makeStyles()((theme) => ({
    activeBorder: {
        boxShadow: "0 0 0 1px #66bb6a",
        border: "1px solid #66bb6a",
    },
    unActiveBorder: {
        border: "1px solid grey",
        "&:hover": {
            border: "1px solid #ffffff",
        },
    },
    readOnlyToolbar: {
        ".ql-toolbar.ql-snow": {
            display: "none",
        },
    },
    editor: {
        boxSizing: "border-box",
        width: '100%',
        margin: theme.spacing(1),
        borderRadius: "4px",
        ".ql-toolbar.ql-snow": {
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "1px solid grey",
        },
        ".ql-toolbar": {
            textAlign: "left",
        },
        ".ql-container": {
            width: "100%",
            minHeight: 'calc(1.876em * 6)',
        },
        ".ql-editor": {
            width: "100%",
            minHeight: "inherit",
            fontFamily: theme.typography.fontFamily,
            fontSize: "1rem"
        },
        ".ql-editor.ql-blank": {
            "&::before": {
                textDecoration: "none",
                fontStyle: "normal",
                fontFamily: theme.typography.fontFamily,
                fontSize: "1rem",
                fontWeight: theme.typography.fontWeightRegular,
                lineHeight: "1.4375em",
                color: theme.palette.text.secondary,
            }
        },
        ".ql-container.ql-snow": {
            border: "none",
        },
        ".ql-toolbar .ql-stroke": {
            fill: "none",
            stroke: "#ffffff",
        },
        ".ql-toolbar .ql-fill": {
            fill: "#ffffff",
            stroke: "none",
        },
        ".ql-toolbar .ql-picker": {
            color: "#ffffff",
        },
        ".ql-snow": {
            ".ql-picker.ql-size": {
                ".ql-picker-item": {
                    "&::before": {
                        content: 'attr(data-value) !important'
                    }
                },
                ".ql-picker-label": {
                    "&::before": {
                        content: 'attr(data-value) !important'
                    }
                }
            }
        },
    },
    label: {
        marginLeft: theme.spacing(2.5),
        background: "#505050",
        paddingRight: "3px",
        paddingLeft: "3px",
        zIndex: 999,
        position: "absolute",
        textAlign: "left",
        display: "block",
    }
}));

type Props = {
    isReadOnly?: boolean;
    property: Property;
    value: string;
    onChange?: (value: OnChangeTexFieldPayload) => void;
}

const areEqual = (previousProps: Props, nextProps: Props) => {
    return previousProps.property === nextProps.property
        && previousProps.value === nextProps.value;
}

function RichTextField(
    {
        isReadOnly,
        property,
        value,
        onChange,
    }: Props) {

    useEffect(() => {
        let Size = Quill.import("attributors/style/size");
        Size.whitelist = ['8px', '9px', '10px', '12px', '14px', '16px', '18px', '20px', '24px']
        Quill.register(Size, true);
    }, [])

    const [isActive, setIsActive] = useState(false);
    const {classes} = useStyles();

    const getToolbar = () => {
        if (isReadOnly) {
            return [];
        }

        return [
            [{'header': [1, 2, 3, 4, false]}],
            [{'size': ['8px', '9px', '10px', '12px', '14px', '16px', '18px', '20px', '24px']}],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            [{'color': COLORS}, {'background': BACKGROUND_COLORS}],
        ];
    }

    return (
        <>
            {!isReadOnly && <Typography variant="caption"
                                        color="textSecondary"
                                        className={classes.label}>
                {property.label}
            </Typography>}
            <ReactQuill id={"quillEditor"}
                        theme="snow"
                        placeholder={property.label}
                        readOnly={isReadOnly}
                        onFocus={() => setIsActive(true)}
                        onBlur={() => setIsActive(false)}
                        modules={{toolbar: getToolbar()}}
                        preserveWhitespace
                        className={clsx(classes.editor, {
                            [classes.activeBorder]: isActive && !isReadOnly,
                            [classes.unActiveBorder]: !isActive && !isReadOnly,
                            [classes.readOnlyToolbar]: isReadOnly,
                        })}
                        value={value}
                        onChange={(value) => {
                            if (onChange) {
                                onChange({fieldName: property.key, fieldValue: value});
                            }
                        }}
            />
        </>
    )
}

export default memo(RichTextField, areEqual);
