import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {Api, ApiContext} from "../../common/api";
import {PutPatientRequest} from "../api/request/PutPatientRequest";
import {toast} from "react-toastify";
import {RoutePaths} from "../../common/constants/routePaths";
import Patient from "./Patient";
import {GetPatientByIdResponse} from "../api/response/GetPatientByIdResponse";
import {AxiosResponse} from "axios";

export default function EditPatient() {
    const navigate = useNavigate();
    const {patientApi} = useContext<Api>(ApiContext);
    const [data, setData] = useState<GetPatientByIdResponse>();
    const {patientId} = useParams();

    useEffect(() => {
        if (patientId) {
            patientApi.getPatientById(Number(patientId))
                .then((res: AxiosResponse<GetPatientByIdResponse>) => {
                    setData(res.data);
                }).catch(() => {
            });
        }
    }, [patientId])

    const handleCancel = async () => {
        navigate(-1);
    }

    const handleUpdate = async (request: PutPatientRequest) => {
        patientApi.putPatient(Number(patientId), request)
            .then(() => {
                toast.success("Zapisano dane pacjenta")
                navigate(RoutePaths.patients.getPath())
            }).catch(() => {
        })
    }

    return (
        <Patient handleCancel={handleCancel}
                 handleSave={handleUpdate}
                 initialData={data}/>
    )
};
