import React, {createContext, useReducer, Dispatch} from "react";

type Action = {
    type: "SHOW_CONFIRM"
    payload: {
        text: string;
    }
} | {
    type: "HIDE_CONFIRM",
}

type ConfirmState = {
    isVisible: boolean;
    text: string;
}

type Props = {
    children: JSX.Element | JSX.Element[];
}

const initialConfirmState: ConfirmState = {
    isVisible: false,
    text: ""
}

const initialDispatch: Dispatch<Action> = () => initialConfirmState;

const ConfirmContext = createContext({
    confirmState: initialConfirmState,
    dispatch: initialDispatch
});

const reducer = (state: ConfirmState, action: Action): ConfirmState => {
    switch (action.type) {
        case "SHOW_CONFIRM":
            return {
                isVisible: true,
                text: action.payload.text
            }
        case "HIDE_CONFIRM":
            return {
                isVisible: false,
                text: state.text,
            }
        default:
            return initialConfirmState
    }
}

const ConfirmProvider = ({children}: Props) => {
    const [confirmState, dispatch] = useReducer(reducer, initialConfirmState);

    return <ConfirmContext.Provider value={{confirmState, dispatch}}>{children}</ConfirmContext.Provider>
}

export {ConfirmContext};
export default ConfirmProvider;

