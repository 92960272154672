import {useCallback, useContext, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import IconButton from "@mui/material/IconButton";
import AppInfiniteLoadingList from "../../../common/components/AppInfiniteLoadingList";
import {Api, ApiContext} from "../../../common/api";
import DocumentListItemView from "./DocumentListItemView";
import CreateDocumentDialog from "./dialogs/CreateDocumentDialog";
import {GetPatientDocumentsResponseItem} from "../../api/response/GetPatientDocumentsResponseItem";
import {toast} from "react-toastify";
import {useSubListStyles} from "../../../common/styles/subListStyles";
import {PostPatientDocumentRequest} from "../../api/request/PostPatientDocumentRequest";

type Props = {
    patientId: number;
}

export default function PatientCardDocumentsList({patientId}: Props) {
    const {classes} = useSubListStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [newItem, setNewItem] = useState({} as GetPatientDocumentsResponseItem)
    const {patientApi} = useContext<Api>(ApiContext);

    const handleConfirmDialog = useCallback((document: PostPatientDocumentRequest) => {
            create(document);
    }, []);

    const create = useCallback((documentData: PostPatientDocumentRequest) => {
        if (documentData) {
            patientApi.postPatientDocument(documentData)
                .then((resp) => {
                    toast.success("Zapisano dokument pacjenta");
                    setIsOpen(false);
                    setNewItem({id: resp.data, text: documentData.text, title: documentData.title})
                }).catch(() => {})
        } else {
            setIsOpen(false);
        }
    }, [])

    const handleCancelDialog = () => {
        setIsOpen(false);
    }

    const handleOpenDialog = useCallback(() => {
        setIsOpen(true);
    }, []);

    return (
        <Box className={classes.container}>
            <CreateDocumentDialog isOpen={isOpen}
                                  handleConfirm={handleConfirmDialog}
                                  handleCancel={handleCancelDialog}
                                  patientId={patientId}
            />
            <Box className={classes.header}>
                <Typography variant="h5">Dokumenty pacjenta</Typography>
                <IconButton size="large" onClick={handleOpenDialog}>
                    <AddBoxRoundedIcon color={"primary"}/>
                </IconButton>
            </Box>
            <Box className={classes.body}>
                <AppInfiniteLoadingList requestParams={patientId}
                                        newItem={newItem}
                                        ListItemComponent={DocumentListItemView}
                                        apiFetchPaginatedItems={patientApi.getPatientDocuments}
                                        apiDeleteItem={patientApi.deletePatientDocument}
                />
            </Box>
        </Box>
    );
}
