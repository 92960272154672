import {makeStyles} from "tss-react/mui";

export const useSubListStyles = makeStyles()((theme) => ({
    container: {
        flexGrow: 1,
        maxWidth: "35%",
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.spacing(1),
        flexWrap: "wrap",
    },
    body: {
        [theme.breakpoints.down('xs')]: {
            maxHeight: "20vmin"
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: "60vmax"
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: "70vmin"
        },
        overflowY: "auto",
        "::-webkit-scrollbar": {
            display: "none"
        },
    }
}))
