import React, {useContext, useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import {Api, ApiContext} from "../../common/api";
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {AxiosResponse} from "axios";
import {HerbData} from "../api/HerbData";
import {Option} from "../api/Option";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {makeStyles} from "tss-react/mui";
import AppDialogTitle from "../../common/components/dialogs/AppDialogTitle";
import ThumbnailImage from "../../images/components/ThumbnailImage";
import {Property} from "../../common/constants/Property";
import {properties} from '../../common/constants/properties';
import RichTextField from "../../common/components/RichTextField/RichTextField";

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    headerContainer: {
        display: 'flex',
        paddingBottom: theme.spacing(2),
    },
    namesContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(4),
    },
    gridCard: {
        height: '100%',
    },
    basicInfoContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    latinName: {
        alignSelf: 'flex-start',
    },
    gridContainer: {
        paddingTop: theme.spacing(2),
    },
    propertyListItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

type Props = {
    herbId: number;
    isOpen: boolean;
    handleClose: () => void;
    children?: React.ReactElement;
}

type HerbPropertyProps = {
    property: Property
    value: string;
}

function PropertyListItem({property, value}: HerbPropertyProps) {
    const {classes} = useStyles();

    if (value) {
        return (
            <ListItem className={classes.propertyListItem}>
                <Typography variant="subtitle1">
                    {property.label}
                </Typography>
                <Typography component={"span"} variant="body1" color="textSecondary">
                    {value}
                </Typography>
            </ListItem>
        )
    } else {
        return null;
    }
}

function RichTextFieldItem({property, value}: HerbPropertyProps) {
    if (value) {
        return (
            <Grid item
                  xs={12}>
                <Card elevation={6}>
                    <CardHeader title={property.label}/>
                    <Divider light/>
                    <CardContent>
                        <RichTextField isReadOnly={true}
                                       property={property}
                                       value={value}
                        />
                    </CardContent>
                </Card>
            </Grid>
        )
    } else {
        return null;
    }
}

function useHerbData(herbId: number, isOpen: boolean): HerbData {
    const {herbsApi} = useContext<Api>(ApiContext);
    const [herb, setHerb] = useState({} as HerbData);

    useEffect(() => {
        if (herbId) {
            herbsApi.getHerbById(herbId).then((res: AxiosResponse<HerbData>) => {
                setHerb(res.data);
            }).catch(() => {
            });
        }
    }, [herbsApi, herbId, isOpen])

    return herb;
}

export default function HerbDialog(props: Props) {
    const {classes} = useStyles();
    const herb = useHerbData(props.herbId, props.isOpen);

    const optionsToString = (property: Property): string => {
        const options: Option[] = herb[property.key];
        let content = "";

        if (options) {
            options.forEach((option: Option) => {
                content = content + option.label + "\n";
            })
        }

        return content;
    }

    const BasicInfoBox = (label: string, value: string) => {
        return (
            <Box className={classes.basicInfoContainer}>
                <Typography variant={"subtitle1"}>
                    {label}
                </Typography>
                <Typography variant={"body1"}
                            color={"textSecondary"}>
                    {value}
                </Typography>
            </Box>)
    }

    return (
        <Dialog open={props.isOpen}
                onClose={props.handleClose}
                scroll={"paper"}
                fullWidth
                maxWidth={"lg"}>
            <AppDialogTitle onClose={props.handleClose}>
                Szczegóły zioła
            </AppDialogTitle>
            <DialogContent className={classes.root} dividers>
                <Card className={classes.headerContainer}
                      elevation={6}>
                    <ThumbnailImage herbId={props.herbId}/>
                    <Box className={classes.namesContainer}>
                        <Typography variant={"h3"}>
                            {herb.name}
                        </Typography>
                        <Typography noWrap
                                    variant={"body1"}
                                    className={classes.latinName}>
                            {herb.nameLatin}
                        </Typography>
                    </Box>
                </Card>
                <Divider light/>
                <Grid container
                      spacing={2}
                      className={classes.gridContainer}
                      alignItems={"stretch"}>
                    <Grid item xs={12}>
                        <Card elevation={6} className={classes.gridCard}>
                            <CardHeader title={properties.herbDetailsViewBasicData.label}/>
                            <Divider light/>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} sm={4} md={3}>
                                        {BasicInfoBox(
                                            properties.herbDailyDose.label,
                                            `${herb.dailyDoseFrom} - ${herb.dailyDoseTo}`)
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        {BasicInfoBox(properties.herbPregnancy.label, herb.pregnancy)}
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        {BasicInfoBox(properties.herbStandardDose.label, herb.standardDose)}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        {BasicInfoBox(properties.herbBoilingTime.label, herb.boilingTime)}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        {BasicInfoBox(properties.herbUsingTime.label, herb.usingTime)}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item
                          xs={12}
                          sm={6}>
                        <Card elevation={6}
                              className={classes.gridCard}>
                            <CardHeader title={properties.herbPharmacological.label}/>
                            <Divider light/>
                            <CardContent>
                                <List>
                                    <PropertyListItem property={properties.herbTastes}
                                                      value={optionsToString(properties.herbTastes)}
                                    />
                                    <PropertyListItem property={properties.herbThermalCharacters}
                                                      value={optionsToString(properties.herbThermalCharacters)}
                                    />
                                    <PropertyListItem property={properties.herbCircuits}
                                                      value={optionsToString(properties.herbCircuits)}
                                    />
                                    <PropertyListItem property={properties.herbWorkingDirection}
                                                      value={herb.workingDirection}
                                    />
                                    <PropertyListItem property={properties.herbActionCenters}
                                                      value={optionsToString(properties.herbActionCenters)}
                                    />
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item
                          xs={12}
                          sm={6}>
                        <Card elevation={6}
                              className={classes.gridCard}>
                            <CardHeader title={properties.herbDetailsViewRemaining.label}/>
                            <Divider light/>
                            <CardContent>
                                <List>
                                    <PropertyListItem property={properties.herbDirectionality}
                                                      value={herb.directionality}
                                    />
                                    <PropertyListItem property={properties.herbContraindications}
                                                      value={herb.contraindications}
                                    />
                                    <PropertyListItem property={properties.herbPharmacological}
                                                      value={herb.pharmacological}
                                    />
                                    <PropertyListItem property={properties.herbToxicology}
                                                      value={herb.toxicology}
                                    />
                                    <PropertyListItem property={properties.herbTolerance}
                                                      value={herb.tolerance}
                                    />
                                    <PropertyListItem property={properties.herbGroups}
                                                      value={optionsToString(properties.herbGroups)}
                                    />
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <RichTextFieldItem property={properties.herbPhysiological}
                                       value={herb.physiological}
                    />
                    <RichTextFieldItem property={properties.herbNotes}
                                       value={herb.notes}
                    />
                    <RichTextFieldItem property={properties.herbCombinations}
                                       value={herb.combinations}/>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
