import {useNavigate} from "react-router-dom";
import {useCallback, useContext, useEffect, useReducer} from "react";
import {Api, ApiContext} from "../../../common/api";
import MaterialTable, {Action} from "@material-table/core";
import {PostFindPatientsRequest} from "../../api/request/PostFindPatientsRequest";
import PostFindPatientsResponse from "../../api/response/PostFindPatientsResponse";
import {PatientActions} from "../../actions/PatientActions";
import {RoutePaths} from "../../../common/constants/routePaths";
import Card from "@mui/material/Card";
import {toast} from "react-toastify";
import {PostFindPatientResponseItem} from "../../api/response/PostFindPatientResponseItem";
import {usePatientListStyle} from "./styles";
import useConfirm from "../../../common/hooks/useConfirm";

type Props = {
    searchParams: PostFindPatientsRequest;
}

type State = {
    page: number;
    size: number;
    data: PostFindPatientsResponse;
}

const defaultState: State = {
    page: 0,
    size: 8,
    data: {} as PostFindPatientsResponse,
}

const listReducer = (state: State, action: PatientActions): State => {
    switch (action.type) {
        case "setListData":
            return {...state, data: action.value};
        case "setPage":
            return {...state, page: action.value};
        case "setSize":
            return {...state, size: action.value};
    }
}

export default function PatientList({searchParams}: Props) {
    const {classes} = usePatientListStyle();
    const navigate = useNavigate();
    const {patientApi} = useContext<Api>(ApiContext);
    const {withConfirm} = useConfirm();
    const [state, dispatchState] = useReducer(listReducer, defaultState)

    useEffect(() => {
        patientApi.postFindPatients(state.page, state.size, searchParams)
            .then((resp: PostFindPatientsResponse) => {
                dispatchState({
                    type: 'setListData',
                    value: resp,
                })
            }).catch(() => {
        });
    }, [searchParams, state.page, state.size])

    const redirect = (address: string, patientId?: number) => {
        if (patientId) {
            navigate(address, {state: {patientId: patientId}});
        } else {
            navigate(address);
        }
    }

    const handleDelete = useCallback(async (patientId: number) => {
        await withConfirm(
            () => deletePatient(patientId),
            "Czy na pewno chcesz usunąć?");
    }, []);

    const deletePatient = (patientId: number): void => {
        patientApi.deletePatient(patientId)
            .then(() => {
                toast.success("Usunięto pacjenta")
            }).catch(() => {
        })
    }

    const getActions = (): (Action<object> | ((rowData: object) => Action<object>))[] => {
        return [
            {
                icon: 'add',
                tooltip: 'Dodaj pacjenta',
                isFreeAction: true,
                onClick: () => redirect(RoutePaths.createPatient.path),
            },
            {
                icon: 'article',
                tooltip: 'Otwórz kartę pacjenta',
                onClick: (event, row: object) => {
                    let patientId = (row as PostFindPatientResponseItem).id;
                    redirect(RoutePaths.patientCard.getPath([patientId]))
                }
            },
            {
                icon: 'edit',
                tooltip: 'Edytuj pacjenta',
                onClick: (event, row: any) => {
                    let patientId = (row as PostFindPatientResponseItem).id;
                    navigate(RoutePaths.editPatient.getPath([patientId]))
                }
            },
            {
                icon: 'delete',
                tooltip: 'Usuń pacjenta',
                onClick: (event, row: object) => handleDelete((row as PostFindPatientResponseItem).id),
            },
        ]
    }

    return (
        <Card className={classes.main}>
            <MaterialTable
                page={state.page}
                totalCount={state.data.totalElements}
                columns={[
                    {title: 'Imię', field: 'name'},
                    {title: 'Nazwisko', field: 'surname'},
                    {title: 'Adres', field: 'address'}
                ]}
                onPageChange={(page => dispatchState({type: 'setPage', value: page}))}
                onRowsPerPageChange={(size) => dispatchState({type: 'setSize', value: size})}
                data={state.data.content}
                title="Lista pacjentów"
                localization={{
                    body: {},
                    header: {
                        actions: 'Akcje'
                    },
                    pagination: {
                        previousTooltip: 'Poprzednia Strona',
                        nextTooltip: 'Następna Strona',
                        lastTooltip: 'Ostatnia Strona',
                        firstTooltip: 'Pierwsza Strona',
                        labelDisplayedRows: '{from} - {to} z {count}',
                        labelRowsPerPage: "Wierszy na stronę",
                    },
                    toolbar: {
                        searchTooltip: 'Szukaj',
                        searchPlaceholder: 'Szukaj',
                    },
                }}
                actions={getActions()}
                options={{
                    pageSize: 8,
                    pageSizeOptions: [8, 16, 24],
                    actionsColumnIndex: -1
                }}
            />
        </Card>
    )
}
