import {makeStyles} from "tss-react/mui";

export const useListItemViewStyle = makeStyles()((theme) => ({
    root: {
        borderRadius: 20,
    },
    buttonBox: {
        width: "100%",
        alignSelf: "flex-end",
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        margin: theme.spacing(1),
        borderRadius: 20
    },
    prescriptionCount: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        flexWrap: "wrap",
    },
    headerBox: {
        margin: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between"
    },
    date: {
        textAlign: "left"
    },
    syndromeBox: {
        display: "flex",
        margin: theme.spacing(1),
        alignItems: "flex-start",
        flexDirection: "column",
    },
    syndrome: {
        textAlign: "left"
    }
}))
