import {makeStyles} from "tss-react/mui";
import {useContext, useEffect, useState} from "react";
import {Api, ApiContext} from "../../common/api";
import AppImage from "./AppImage";

const useStyles = makeStyles()((theme) => ({
    thumbnail: {
        maxWidth: 192,
        maxHeight: 192,
    },
}));

export default function ThumbnailImage({herbId, imageFile}: { herbId: number, imageFile?: File }) {
    const {classes} = useStyles();
    const [file, setFile] = useState<File>(null);
    const {imagesApi} = useContext<Api>(ApiContext);

    useEffect(() => {
        if (herbId) {
            imagesApi.getThumbnailImageFile(herbId)
                .then((resp: File) => {
                    setFile(resp);
                }).catch(() => {});
        }
    }, [herbId])

    useEffect(() => {
        setFile(imageFile);
    }, [imageFile])

    return (
        <AppImage
            className={classes.thumbnail}
            file={file}
            onError={(event: any) => event.target.src = "empty.png"}
            alt={"Brak miniaturki"}/>
    );
}
