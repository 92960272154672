import {Medicine} from "../api/response/Medicine";
import React, {useContext, useEffect, useState} from "react";
import {Option} from "../../herbs/api/Option";
import {throttle} from "lodash";
import {PostFindAutocompleteNameRequest} from "../../herbs/api/request/PostFindAutocompleteNameRequest";
import {Grid, ListItem, TextField, Tooltip} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import {Api, ApiContext} from "../../common/api";
import {AxiosResponse} from "axios";
import {GetHerbInMedicineDataResponse} from "../../herbs/api/response/GetHerbInMedicineDataResponse";
import {makeStyles} from "tss-react/mui";
import {properties} from "../../common/constants/properties";

const useStyles = makeStyles()((theme) => ({
    itemRoot: {
        width: "100%",
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    textField: {
        width: "100%",
    },
}))

type Props = {
    index: number;
    itemData: Medicine;
    medicineChangeHandler: (index: number, propName: string, value: string) => void;
    deleteMedicineHandler: (index: number) => void;
    isReadOnly: boolean;
}

export default function MedicineListItem(props: Props) {
    const {
        index,
        itemData,
        medicineChangeHandler,
        deleteMedicineHandler,
        isReadOnly
    } = props;

    const [inputNameValue, setInputNameValue] = useState('');
    const [options, setOptions] = useState<readonly Option[]>([]);
    const [currentNameOption, setCurrentNameOption] = useState<Option>(null);
    const {herbsApi} = useContext<Api>(ApiContext);
    const {classes} = useStyles();

    const fetchOptions = React.useMemo(() => throttle(
        (
            request: PostFindAutocompleteNameRequest,
            callback: (results?: readonly Option[]) => void
        ) => {
            herbsApi.postHerbNameAutocompleteOptions(request)
                .then((resp: AxiosResponse<Option[]>) => {
                    callback(resp.data);
                }).catch(() => {
            });
        },
        200
    ), [])

    useEffect(() => {
        if (itemData) {
            setCurrentNameOption({id: undefined, label: itemData.name});
        }
    }, [itemData.name])

    useEffect(() => {
        if (currentNameOption && currentNameOption.id) {
            herbsApi.getHerbInMedicineData(currentNameOption.id)
                .then((resp: AxiosResponse<GetHerbInMedicineDataResponse>) => {
                    const data = resp.data;
                    medicineChangeHandler(
                        index,
                        properties.medicineListHerbName.key,
                        data.latinName
                    );
                    medicineChangeHandler(
                        index,
                        properties.medicineListBoilingTime.key,
                        data.boilingTime
                    );
                    medicineChangeHandler(
                        index,
                        properties.medicineListDose.key,
                        data.standardDose
                    );
                }).catch(() => {
            })
        }
    }, [currentNameOption])

    useEffect(() => {
        if (inputNameValue) {
            medicineChangeHandler(index, properties.medicineListHerbName.key, inputNameValue);
        }
    }, [inputNameValue])

    useEffect(() => {
        let active = true;

        if (inputNameValue === '') {
            setOptions(inputNameValue ? [inputNameValue] : []);
            return undefined;
        }

        fetchOptions(
            {searchString: inputNameValue, isLatin: true},
            (results?: readonly Option[]) => {
                if (active) {
                    let newOptions: readonly Option[] = [];

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });

        return () => {
            active = false;
        };
    }, [inputNameValue, fetchOptions])

    return (
        <ListItem alignItems={"flex-start"} className={classes.itemRoot}>
            <h3>{index + 1}.</h3>
            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} lg={4}>
                    <Autocomplete options={options}
                                  freeSolo
                                  filterOptions={(x) => x}
                                  autoComplete
                                  disableClearable={isReadOnly}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  filterSelectedOptions
                                  getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                                  value={currentNameOption}
                                  onChange={(event: any, newValue: Option) => {
                                      setCurrentNameOption(newValue);
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                      setInputNameValue(newInputValue);
                                  }}
                                  renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          label="Nazwa"
                                          value={inputNameValue}
                                          variant="outlined"
                                          inputProps={{
                                              type: "search",
                                              ...params.inputProps,
                                              readOnly: isReadOnly,
                                              disabled: isReadOnly
                                          }}
                                      />
                                  )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                        className={classes.textField}
                        multiline
                        label={properties.medicineListBoilingTime.label}
                        variant="outlined"
                        value={itemData.boilingTime}
                        inputProps={{readOnly: isReadOnly, disabled: isReadOnly}}
                        onChange={(event) => {
                            medicineChangeHandler(
                                index,
                                properties.medicineListBoilingTime.key,
                                event.target.value
                            );
                        }}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                        className={classes.textField}
                        multiline
                        label={properties.medicineListDose.label}
                        variant="outlined"
                        value={itemData.dose}
                        inputProps={{readOnly: isReadOnly, disabled: isReadOnly}}
                        onChange={(event) => {
                            medicineChangeHandler(
                                index,
                                properties.medicineListDose.key,
                                event.target.value
                            );
                        }}/>
                </Grid>
            </Grid>
            {!isReadOnly &&
                <Tooltip title={"Usuń zioło"}>
                    <IconButton onClick={() => deleteMedicineHandler(index)} size="large">
                        <Delete/>
                    </IconButton>
                </Tooltip>
            }
        </ListItem>
    )
};
