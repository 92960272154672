import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import {Box, Button, Grid, MenuItem, Tab, TextField} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {PostPatientRequest} from "../api/request/PostPatientRequest";
import AppTextField, {OnChangeTexFieldPayload} from "../../common/components/AppTextField";
import {Sex} from "../types/Sex";
import {properties} from "../../common/constants/properties";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {GetPatientByIdResponse} from "../api/response/GetPatientByIdResponse";
import {PutPatientRequest} from "../api/request/PutPatientRequest";
import {Dayjs} from "dayjs";
import useConfirm from "../../common/hooks/useConfirm";

//TODO: React rerenders birthday field and sex field every time state changes because lack of memoization
const useStyles = makeStyles()((theme) => ({
    mainBox: {
        width: "100%",
    },
    tabListBox: {
        width: "100%",
        borderBottom: 1,
        borderColor: "divider",
    },
    textField: {
        width: '100%',
        margin: theme.spacing(1),
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        margin: theme.spacing(1),
        borderRadius: 20
    },
    tab: {
        textTransform: "none",
        fontSize: "large",
    }
}));

const defaultState: PostPatientRequest = {
    name: "",
    birthday: null,
    city: "",
    email: "",
    occupation: "",
    postalCode: "",
    pregnant: false,
    sex: Sex.MALE,
    street: "",
    streetNumber: "",
    surname: "",
    telephone: "",
    bloodType: ""
};

type Props = {
    handleCancel: () => void;
    handleSave: (request: PostPatientRequest | PutPatientRequest) => void;
    initialData?: GetPatientByIdResponse;
}

export default function Patient(
    {
        handleCancel,
        handleSave,
        initialData
    }: Props) {

    const {classes} = useStyles();
    const [state, setState] = useState(defaultState);
    const [tab, setTab] = useState("0");
    const {withConfirm} = useConfirm();

    useEffect(() => {
        if (initialData) {
            setState(initialData);
        }
    }, [initialData])

    const onSubmit = async () => {
        await withConfirm(() => handleSave(state), "Czy na pewno chcesz zapisać?");
    }

    const onCancel = async () => {
        await withConfirm(handleCancel, "Czy na pewno chcesz anulować?");
    }

    const handleTabChange = (tab: string) => {
        setTab(tab);
    }

    const handleTextFieldChange = (payload: OnChangeTexFieldPayload) => {
        setState(prevState => ({...prevState, [payload.fieldName]: payload.fieldValue}));
    }

    const handleBirthdayChange = useCallback((date: Dayjs | null) => {
        setState(prevState => ({...prevState, birthday: date}));
    }, []);

    const handleSexChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let value: Sex = event.target.value as unknown as Sex;
        setState(prevState => ({...prevState, sex: value, pregnant: value === Sex.MALE ? false : prevState.pregnant}));
    }, []);

    const handlePregnancyChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({...prevState, pregnant: JSON.parse(event.target.value)}));
    }, []);

    return (
        <Box className={classes.mainBox}>
            <TabContext value={tab}>
                <Box className={classes.tabListBox}>
                    <TabList onChange={(event, value) => handleTabChange(value)}
                             variant="fullWidth">
                        <Tab className={classes.tab} label="Dane podstawowe" value="0"/>
                        <Tab className={classes.tab} label="Dane adresowe" value="1"/>
                    </TabList>
                </Box>
                <TabPanel value="0">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientName}
                                          value={state.name}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientSurname}
                                          value={state.surname}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name={properties.patientPregnancy.key}
                                       select
                                       disabled={state.sex === Sex.MALE}
                                       label={properties.patientPregnancy.label}
                                       value={state.pregnant}
                                       onChange={handlePregnancyChange}
                                       className={classes.textField}
                            >
                                <MenuItem value={'true'}>Tak</MenuItem>
                                <MenuItem value={'false'}>Nie</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientOccupation}
                                          value={state.occupation}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
                                <DatePicker
                                    label={properties.patientBirthday.label}
                                    inputFormat={"dd/MM/yyyy"}
                                    onChange={handleBirthdayChange}
                                    className={classes.textField}
                                    value={state.birthday}
                                    renderInput={(params) => (
                                        <TextField
                                            variant={"outlined"}
                                            {...params} />)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientBloodType}
                                          value={state.bloodType}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField id={properties.patientSex.key}
                                       select
                                       label={properties.patientSex.label}
                                       value={state.sex}
                                       onChange={handleSexChange}
                                       className={classes.textField}
                            >
                                <MenuItem value={Sex.MALE}>Mężczyzna</MenuItem>
                                <MenuItem value={Sex.FEMALE}>Kobieta</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value="1">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientCity}
                                          value={state.city}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientStreet}
                                          value={state.street}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientStreetNumber}
                                          value={state.streetNumber}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientPostalCode}
                                          value={state.postalCode}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientEmail}
                                          inputMode={"email"}
                                          value={state.email}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AppTextField property={properties.patientTelephone}
                                          value={state.telephone}
                                          onChange={handleTextFieldChange}
                                          className={classes.textField}/>
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
            <Box className={classes.buttonBox}>
                <Button className={classes.button}
                        onClick={onCancel}
                        variant="outlined">
                    Anuluj
                </Button>
                <Button className={classes.button}
                        onClick={onSubmit}
                        variant="contained">
                    Zapisz
                </Button>
            </Box>
        </Box>
    )
}
