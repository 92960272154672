import {makeStyles} from "tss-react/mui";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Button, Tab} from "@mui/material";
import VisitBasicDataForm from "./VisitBasicDataForm";
import VisitGallery from "./VisitGallery";
import VisitPrescriptions from "./VisitPrescriptions";
import React, {useState} from "react";
import {VisitActions} from "../../actions/VisitActions";
import {VisitData} from "../../reducers/visitReducer";
import useConfirm from "../../../common/hooks/useConfirm";

const useStyles = makeStyles()((theme) => ({
    buttonBox: {
        position: "fixed",
        bottom: theme.spacing(2),
        zIndex: 100,
        alignSelf: "flex-end",
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        margin: theme.spacing(1),
        borderRadius: 20
    },
    tabNavigation: {
        gap: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "baseline",
    },
    tabList: {
        width: "100%",
        borderBottom: 1,
        borderColor: "divider",
    },
    tab: {
        textTransform: "none",
        fontSize: "large",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    tabPanel: {
        marginBottom: theme.spacing(8)
    }
}));

type Props = {
    visitData: VisitData
    handleCancelClick?: () => void;
    handleSaveClick?: () => void;
    isReadOnly?: boolean;
    visitDispatcher?: (action: VisitActions) => void;
}

export default function Visit(props: Props) {
    const {classes} = useStyles();
    const [tab, setTab] = useState("0");
    const {withConfirm} = useConfirm();

    const {
        visitData,
        handleCancelClick,
        handleSaveClick,
        isReadOnly,
        visitDispatcher
    } = props;

    const onSubmit = async () => {
        await withConfirm(handleSaveClick, "Czy na pewno chcesz zapisać?");
    }

    const onCancel = async () => {
        await withConfirm(handleCancelClick, "Czy na pewno chcesz anulować?");
    }

    const handleTabChange = (tab: string) => {
        setTab(tab);
    }

    return (
        <Box className={classes.root}>
            <TabContext value={tab}>
                <Box className={classes.tabNavigation}>
                    {!isReadOnly && <Button className={classes.button}
                                            onClick={onCancel}
                                            variant="outlined">
                        Anuluj
                    </Button>}
                    <TabList className={classes.tabList}
                             onChange={(event, value) => handleTabChange(value)}
                             variant="fullWidth">
                        <Tab className={classes.tab} label="Podstawowe informacje" value="0"/>
                        <Tab className={classes.tab} label="Galeria" value="1"/>
                        <Tab className={classes.tab} label="Recepty" value="2"/>
                    </TabList>
                    {!props.isReadOnly && <Button className={classes.button}
                                                  onClick={onSubmit}
                                                  variant="contained">
                        Zapisz
                    </Button>}
                </Box>
                <TabPanel value="0" className={classes.tabPanel}>
                    <VisitBasicDataForm isReadOnly={isReadOnly}
                                        data={visitData.basicData}
                                        dispatchChange={visitDispatcher}
                    />
                </TabPanel>
                <TabPanel value="1" className={classes.tabPanel}>
                    <VisitGallery visitImages={visitData.visitImages}
                                  isReadOnly={isReadOnly}
                                  dispatchChange={visitDispatcher}
                    />
                </TabPanel>
                <TabPanel value={"2"} className={classes.tabPanel}>
                    <VisitPrescriptions data={visitData.prescriptions}
                                        isReadOnly={isReadOnly}
                                        dispatchChange={visitDispatcher}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    )
}
