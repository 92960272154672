export class RoutePaths {
    public static readonly landing = new RoutePaths(
        '/',
        'landing');
    public static readonly login = new RoutePaths(
        '/login',
        'login');
    public static readonly herbs = new RoutePaths(
        '/herbs',
        'herbs');
    public static readonly createHerb = new RoutePaths(
        '/herbs/new',
        'newHerb');
    public static readonly editHerb = new RoutePaths(
        '/herbs/:herbId/edit',
        'editHerb',
        (params) => `/herbs/${params[0]}/edit`);
    public static readonly prescriptionsPractice = new RoutePaths(
        '/prescriptions/practice',
        'prescriptions');
    public static readonly patients = new RoutePaths(
        '/patients',
        'patients');
    public static readonly createPatient = new RoutePaths(
        '/patients/new',
        'newPatient')
    public static readonly editPatient = new RoutePaths(
        '/patients/:patientId/edit',
        'editPatient',
        (params) => `/patients/${params[0]}/edit`)
    public static readonly patientCard = new RoutePaths(
        '/patients/:patientId/card',
        'patientCard',
        (params) => `/patients/${params[0]}/card`)
    public static readonly createVisit = new RoutePaths(
        "/patients/:patientId/visits/new",
        "newVisit",
        (params) => `/patients/${params[0]}/visits/new`)
    public static readonly editVisit = new RoutePaths(
        "/patients/:patientId/visits/:visitId/edit",
        "editVisit",
        (params) => `/patients/${params[0]}/visits/${params[1]}/edit`
    )

    private constructor(public readonly path: string,
                        public readonly route: string,
                        private readonly parametrizedPath?: (params: (string | number)[]) => string) {
    }

    getPath(params?: (string | number)[]): string {
        if (this.parametrizedPath && params.length > 0) {
            return this.parametrizedPath(params);
        }

        return this.path;
    }
}
