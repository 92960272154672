import React from 'react';
import {createRoot} from 'react-dom/client';
import 'typeface-roboto';
import * as serviceWorker from './serviceWorker';
import App from "./common/components/App/App";

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App/>);

// If you want your App to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
