import React, {useContext, useEffect, useState} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import {PostSearchHerbsRequest} from "../api/request/PostSearchHerbsRequest";
import {AxiosResponse} from "axios";
import {Api, ApiContext} from "../../common/api";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Option} from "../api/Option";
import {makeStyles} from "tss-react/mui";
import {InputBase} from "@mui/material";
import {debounce, throttle} from "lodash";

const useStyles = makeStyles()((theme) => ({
    search: {
        width: '100%',
    },
    input: {
        width: '100%',
    },
    latinCheckbox: {
        float: "right",
        wordWrap: "inherit"
    }
}));

type Props = {
    onFilterChange?: (filter: PostSearchHerbsRequest) => void;
}

export default function HerbNameSearchTextField({onFilterChange}: Props) {
    const {classes} = useStyles();
    const {herbsApi} = useContext<Api>(ApiContext);
    const [options, setOptions] = useState<readonly Option[]>([] as Option[]);
    const [nameString, setNameString] = useState('');
    const [latinChecked, setLatinChecked] = useState(false);

    const fetchOptions = React.useMemo(() => throttle((
            searchString: string,
            isLatin: boolean,
            setResults: (results?: readonly Option[]) => void
        ) => {
            herbsApi.postHerbNameAutocompleteOptions({searchString: searchString, isLatin: isLatin})
                .then((res: AxiosResponse<Option[]>) => {
                    setResults(res.data);
                }).catch(() => {
            });
        }, 200
    ), []);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLatinChecked(event.target.checked);
    }

    const updateFilter = React.useMemo(() => debounce((
            searchString: string,
            isLatin: boolean
        ) => {
            if (isLatin) {
                onFilterChange({nameLatin: searchString, name: ''} as PostSearchHerbsRequest);
            } else {
                onFilterChange({name: searchString, nameLatin: ''} as PostSearchHerbsRequest);
            }
        }, 500
    ), []);

    useEffect(() => {
        let active = true;

        if (nameString === '') {
            setOptions([]);
            updateFilter(nameString, latinChecked);
            return undefined;
        }

        fetchOptions(
            nameString,
            latinChecked,
            (results?: readonly Option[]) => {
                if (active) {
                    let newOptions: readonly Option[] = [];

                    if (results) {
                        newOptions = [...newOptions, ...results]
                    }

                    setOptions(newOptions);
                    updateFilter(nameString, latinChecked);
                }
            }
        )

        return () => {
            active = false;
        };
    }, [nameString, latinChecked, fetchOptions]);

    return (
        <Autocomplete freeSolo
                      className={classes.search}
                      id='herbSearch'
                      options={options}
                      getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                      onInputChange={(e, v) => setNameString(v)}
                      value={{label: nameString}}
                      renderInput={(params) => {
                          const {InputLabelProps, InputProps, ...rest} = params
                          return <InputBase {...InputProps}
                                            {...rest}
                                            type={"text"}
                                            multiline
                                            placeholder={"Nazwa zioła"}
                                            className={classes.input}
                                            onClick={(e) => e.stopPropagation()}
                                            endAdornment={
                                                <FormControlLabel
                                                    className={classes.latinCheckbox}
                                                    control={
                                                        <Checkbox color={"primary"}
                                                                  checked={latinChecked}
                                                                  name={"search_latin"}
                                                                  onChange={handleCheckboxChange}/>
                                                    }
                                                    label="Nazwy Łacińskie"
                                                />
                                            }
                          />
                      }}
        />
    );
};
