import Herb from "./Herb";
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useReducer, useState} from "react";
import {Api, ApiContext} from "../../common/api";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {emptyHerbData, herbReducer} from "../reducers/herbReducer";
import {RoutePaths} from "../../common/constants/routePaths";

export default function EditHerb() {
    const {herbId} = useParams();
    const {herbsApi, imagesApi} = useContext<Api>(ApiContext);
    const [herbData, dispatchHerbData] = useReducer(herbReducer, emptyHerbData)
    const navigate = useNavigate();
    const [thumbnail, setThumbnail] = useState<File>()

    useEffect(() => {
        if (herbId) {
            herbsApi.getHerbById(Number(herbId))
                .then((res: AxiosResponse) => {
                    dispatchHerbData({type: 'setData', data: res.data});
                    toast.success("Poprawnie pobrano dane zioła");
                })
                .catch(() => {
                });
        }
    }, [herbsApi, herbId]);

    const update = () => {
        herbsApi.putHerb(herbData)
            .then((res: AxiosResponse<number>) => {
                updateThumbnail(res.data);
                toast.success('Zmodyfikowano zioło');
                navigate(RoutePaths.herbs.path);
            }).catch(() => {
        });
    }

    const cancel = () => {
        navigate(-1);
    }

    const updateThumbnail = (herbId: number) => {
        if (thumbnail) {
            imagesApi.postThumbnailImage(herbId, thumbnail)
                .then((res: AxiosResponse) => {
                    toast.success("Zmieniono minaturkę miniaturkę");
                })
                .catch(() => {
                });
        }
    }

    return (
        <Herb herbData={herbData}
              handleCancelClick={cancel}
              handleSaveClick={update}
              herbDispatcher={dispatchHerbData}
              setThumbnail={setThumbnail}/>
    )
};