import {useNavigate} from "react-router-dom";
import React, {useContext, useReducer, useState} from "react";
import {Api, ApiContext} from "../../common/api";
import {emptyHerbData, herbReducer} from "../reducers/herbReducer";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {RoutePaths} from "../../common/constants/routePaths";
import {PostHerbRequest} from "../api/request/PostHerbRequest";
import Herb from "./Herb";


export default function CreateHerb() {
    const {herbsApi, imagesApi} = useContext<Api>(ApiContext);
    const [herbData, dispatchHerbData] = useReducer(herbReducer, emptyHerbData)
    const navigate = useNavigate();
    const [thumbnail, setThumbnail] = useState<File>()

   const create = () => {
        herbsApi.postHerb({...herbData} as PostHerbRequest)
            .then((res: AxiosResponse<number>) => {
                createThumbnail(res.data);
                toast.success('Dodano zioło');
                navigate(RoutePaths.herbs.path);
            }).catch(() => {
        });
    };

    const cancel = () => {
        navigate(-1);
    }

    const createThumbnail = (herbId: number) => {
        if (thumbnail) {
            imagesApi.postThumbnailImage(herbId, thumbnail)
                .then((res: AxiosResponse) => {
                    toast.success("Dodano wybraną miniaturkę");
                })
                .catch(() => {
                });
        }
    }

    return (
        <Herb herbData={herbData}
              handleCancelClick={cancel}
              handleSaveClick={create}
              herbDispatcher={dispatchHerbData}
              setThumbnail={setThumbnail}/>
    )
};