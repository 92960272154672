import {makeStyles} from "tss-react/mui";
import {Box, IconButton, ImageList, ImageListItem, ImageListItemBar} from "@mui/material";
import VisitImage from "./VisitImage";
import {Delete} from "@mui/icons-material";
import React, {useState} from "react";
import ExpandImageDialog from "./ExpandImageDialog";

const IMAGE_WIDTH = 256;
const IMAGE_HEIGHT = 192;

const useStyles = makeStyles()((theme) => ({
    listContainer: {
        display: "flex",
        justifyContent: "center"
    },
    iconButton: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    itemBar: {
        background: "transparent",
    },
    listItem: {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
    },
    image: {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
    }
}));

type Props = {
    removeExistingHandler: (imageId: number) => void;
    imageIds: number[];
}

type State = {
    clickedFile: File,
    open: boolean,
}

export default function VisitEditableGallery({imageIds, removeExistingHandler}: Props) {
    const {classes} = useStyles();
    const [state, setState] = useState<State>({clickedFile: null, open: false});

    const onClose = () => {
        setState({clickedFile: null, open: false});
    }

    const onItemClick = (imageFile: File) => {
        setState({clickedFile: imageFile, open: true});
    }

    return (
        <Box className={classes.listContainer}>
            <ExpandImageDialog open={state.open} onClose={onClose} imageFile={state.clickedFile}/>
            <ImageList cols={4}>
                {imageIds.map((imageId) => (
                    <ImageListItem key={imageId} className={classes.listItem}>
                        <VisitImage imageId={imageId}
                                    className={classes.image}
                                    onClickHandler={onItemClick}
                        />
                        <ImageListItemBar className={classes.itemBar}
                                          actionIcon={
                                              <IconButton onClick={() => removeExistingHandler(imageId)}
                                                          className={classes.iconButton}>
                                                  <Delete/>
                                              </IconButton>
                                          }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    )
}
