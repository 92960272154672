import {Api, ApiContext} from "../../common/api";
import {useContext, useEffect, useState} from "react";
import AppImage from "./AppImage";

type Props = {
    imageId: number;
    className?: string;
    onClickHandler?: (imageFile: File) => void;
}

export default function VisitImage({imageId, className, onClickHandler}: Props) {
    const {imagesApi} = useContext<Api>(ApiContext)
    const [imageFile, setImageFile] = useState<File>(null);

    useEffect(() => {
        if (imageId) {
            imagesApi.getImageFileById(imageId)
                .then((resp: File) => {
                    setImageFile(resp);
                }).catch(() => {})
        }
    }, [imageId])

    return (
        <AppImage alt={"empty.png"}
                  file={imageFile}
                  onError={(event: any) => event.target.src = "empty.png"}
                  className={className}
                  onClick={onClickHandler}
        />
    )
};
