import {GetPatientDocumentsResponseItem} from "../../../api/response/GetPatientDocumentsResponseItem";
import {PatientDocumentDto} from "../../../types/PatientDocumentDto";
import {PutPatientDocumentRequest} from "../../../api/request/PutPatientDocumentRequest";
import DocumentDialogView from "./DocumentDialogView";

type Props = {
    isOpen: boolean;
    data: GetPatientDocumentsResponseItem;
    handleConfirm: (data?: PutPatientDocumentRequest) => void;
    handleCancel: () => void;
}

export default function UpdateDocumentDialog(props: Props) {
    const {
        isOpen,
        handleConfirm,
        handleCancel,
        data
    } = props;

    const handleUpdate = (newData: PatientDocumentDto) => {
        if (newData) {
            handleConfirm({text: newData.text, title: newData.title} as PutPatientDocumentRequest)
        } else {
            handleCancel();
        }
    }

    return (
        <DocumentDialogView isOpen={isOpen}
                            handleClose={handleUpdate}
                            title={data.title}
                            text={data.text}
        />
    )
}
