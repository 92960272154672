import {makeStyles} from "tss-react/mui";
import {VisitImages} from "../../patient/types/VisitImages";
import {DropzoneAreaBase, FileObject} from "mui-file-dropzone";
import VisitEditableGallery from "./VisitEditableGallery";

type Props = {
    data: VisitImages;
    removeNewImageHandler: (index: number) => void;
    removeExistingImageHandler: (imageId: number) => void;
    addNewImagesHandler: (newImages: FileObject[]) => void;
}

export default function VisitGalleryEditor(props: Props) {
    const {
        data,
        removeExistingImageHandler,
        removeNewImageHandler,
        addNewImagesHandler
    } = props

    return (
        <>
            <DropzoneAreaBase fileObjects={data.newImages}
                              filesLimit={100}
                              showAlerts={false}
                              dropzoneText={"Przeciągnij pliki lub kliknij"}
                              onDelete={(deletedFileObject, index) => removeNewImageHandler(index)}
                              onAdd={addNewImagesHandler}
            />
            {data.existingImageIds &&
                <VisitEditableGallery removeExistingHandler={removeExistingImageHandler}
                                      imageIds={data.existingImageIds}
                />
            }
        </>
    )
}

