import {JwtResponse} from "../../auth/api/response/JwtResponse";

export const getTokenFromStorage = (): string => {
    const user: JwtResponse = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
        return `${user.tokenType} ${user.token}`;
    } else {
        return '';
    }
}

export const getUsernameFromStorage = (): string => {
    const user: JwtResponse = JSON.parse(localStorage.getItem("user"));

    if (user) {
        return user.username;
    } else {
        return null;
    }
};

export const copyObject = <T extends object>(obj: T): T => {
    return JSON.parse(JSON.stringify(obj));
}

export const parseDate = (date: Date): string => {
    if (date) {
        date = new Date(date);
        return date.toISOString().substring(0, 10);
    }
}

