import React, {useCallback} from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {HerbData} from "../api/HerbData";
import {Option} from "../api/Option";
import AppTextField, {OnChangeTexFieldPayload} from "../../common/components/AppTextField";
import HerbChipsField from "./HerbChipsField";
import {makeStyles} from "tss-react/mui";
import {HerbListActions} from "../actions/HerbListActions";
import RichTextField from "../../common/components/RichTextField/RichTextField";
import {OptionKey} from "../api/OptionKey";
import {properties} from "../../common/constants/properties";
import {HerbActions} from "../actions/HerbActions";

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: 'auto',
        height: '100%',
    },
    formContainer: {
        width: '50%',
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%',
        margin: theme.spacing(1),
    },
    textFieldPhysiological: {
        width: '100%',
        margin: theme.spacing(1),
        "& textarea": {
            minHeight: 'calc(1.876em * 10)'
        },
    },
    textFieldNotes: {
        width: '100%',
        margin: theme.spacing(1),
        "& textarea": {
            minHeight: 'calc(1.876em * 4)'
        },
    },
    textFieldToxicology: {
        width: '100%',
        margin: theme.spacing(1),
        "& textarea": {
            minHeight: 'calc(1.876em * 2)'
        },
    },
    textFieldTolerance: {
        width: '100%',
        margin: theme.spacing(1),
        "& textarea": {
            minHeight: 'calc(1.876em * 2)'
        },
    }
}));

type Props = {
    dispatchHerbData: (action: HerbActions) => void;
    herbData: HerbData;
};

export default function HerbForm({herbData, dispatchHerbData}: Props) {
    const {classes} = useStyles();

    const onChangeChips = (formFieldName: string, values: any[]) => {
        if (herbData[formFieldName].length < values.length) {
            let lastElement: Option | string = values[values.length - 1];
            values[values.length - 1] = {
                id: values.indexOf(lastElement),
                label: typeof lastElement === "string" ? lastElement : lastElement.label
            } as Option;
        }

        dispatchHerbData({type: 'change', fieldName: formFieldName, fieldValue: values});
    };

    const onChangeTextField = useCallback((change: OnChangeTexFieldPayload) => {
        dispatchHerbData({
            type: "change",
            fieldName: change.fieldName,
            fieldValue: change.fieldValue,
        })
    }, [])

    return (
        <Grid container
              spacing={2}>
            <Grid item
                  xs={12}>
                <Typography variant="h6"
                            color="textPrimary">
                    Nazwa
                </Typography>
            </Grid>
            <Grid item
                  sm={6}
                  xs={12}>
                <AppTextField property={properties.herbName}
                              value={herbData.name}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  sm={6}
                  xs={12}>
                <AppTextField property={properties.herbNameLatin}
                              value={herbData.nameLatin}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            {/*Właściwości farmakologiczne*/}
            <Grid item
                  xs={12}>
                <Typography variant="h6"
                            color="textPrimary">
                    Właściwości farmakologiczne
                </Typography>
            </Grid>
            <Grid item
                  xs={12}>
                <HerbChipsField optionKey={OptionKey.TASTE}
                                property={properties.herbTastes}
                                value={herbData.herbTastes}
                                onChange={onChangeChips}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <HerbChipsField optionKey={OptionKey.THERMAL_CHARACTER}
                                property={properties.herbThermalCharacters}
                                value={herbData.herbThermalCharacters}
                                onChange={onChangeChips}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <HerbChipsField optionKey={OptionKey.CIRCUIT}
                                property={properties.herbCircuits}
                                value={herbData.herbCircuits}
                                onChange={onChangeChips}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <AppTextField property={properties.herbWorkingDirection}
                              value={herbData.workingDirection}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <HerbChipsField optionKey={OptionKey.ACTION_CENTER}
                                property={properties.herbActionCenters}
                                value={herbData.herbActionCenters}
                                onChange={onChangeChips}
                />
            </Grid>
            {/*Właściwości farmakologiczne end*/}
            <Grid item
                  xs={12}>
                <Typography variant="h6"
                            color="textPrimary">
                    Dawkowanie
                </Typography>
            </Grid>
            <Grid item
                  sm={4}
                  xs={12}>
                <AppTextField property={properties.herbDailyDoseFrom}
                              value={herbData.dailyDoseFrom}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  sm={4}
                  xs={12}>
                <AppTextField property={properties.herbDailyDoseTo}
                              value={herbData.dailyDoseTo}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  sm={4}
                  xs={12}>
                <AppTextField property={properties.herbStandardDose}
                              value={herbData.standardDose}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  sm={6}
                  xs={12}>
                <AppTextField property={properties.herbBoilingTime}
                              value={herbData.boilingTime}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  sm={6}
                  xs={12}>
                <AppTextField property={properties.herbPregnancy}
                              value={herbData.pregnancy}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <AppTextField property={properties.herbUsingTime}
                              value={herbData.usingTime}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <Typography variant="h6"
                            color="textPrimary">
                    Pozostałe
                </Typography>
            </Grid>
            <Grid item
                  xs={12}>
                <AppTextField property={properties.herbDirectionality}
                              value={herbData.directionality}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <AppTextField property={properties.herbContraindications}
                              value={herbData.contraindications}
                              onChange={onChangeTextField}
                              className={classes.textField}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <RichTextField property={properties.herbPhysiological}
                               value={herbData.physiological}
                               onChange={onChangeTextField}
                />
                {/*<AppTextField property={properties.herbPhysiological}*/}
                {/*              value={herbData.physiological}*/}
                {/*              onChange={onChangeTextField}*/}
                {/*              className={classes.textFieldPhysiological}*/}
                {/*/>*/}
            </Grid>
            <Grid item
                  xs={12}>
                <AppTextField property={properties.herbPharmacological}
                              value={herbData.pharmacological}
                              className={classes.textField}
                              onChange={onChangeTextField}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <AppTextField property={properties.herbToxicology}
                              value={herbData.toxicology}
                              onChange={onChangeTextField}
                              className={classes.textFieldToxicology}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <AppTextField property={properties.herbTolerance}
                              value={herbData.tolerance}
                              onChange={onChangeTextField}
                              className={classes.textFieldTolerance}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <HerbChipsField optionKey={OptionKey.GROUP}
                                property={properties.herbGroups}
                                value={herbData.herbGroups}
                                onChange={onChangeChips}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <RichTextField property={properties.herbNotes}
                               value={herbData.notes}
                               onChange={onChangeTextField}
                />
            </Grid>
            <Grid item
                  xs={12}>
                <RichTextField property={properties.herbCombinations}
                               value={herbData.combinations}
                               onChange={onChangeTextField}
                />
            </Grid>
        </Grid>
    )
}
