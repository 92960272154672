import ApiBase from "../../common/api";
import {PostFindPatientsRequest} from "./request/PostFindPatientsRequest";
import {AxiosResponse} from "axios";
import PostFindPatientsResponse from "./response/PostFindPatientsResponse";
import * as URL from "../../common/constants/endpoints";
import {PostPatientRequest} from "./request/PostPatientRequest";
import {GetPatientCardBasicDataResponse} from "./response/GetPatientCardBasicDataResponse";
import {PostVisitRequest} from "./request/PostVIsitRequest";
import GetPatientVisitsResponse from "./response/GetPatientVisitsResponse";
import {GetVisitDetailsResponse} from "./response/GetVisitDetailsResponse";
import GetPatientDocumentsResponse from "./response/GetPatientDocumentsResponse";
import {PostPatientDocumentRequest} from "./request/PostPatientDocumentRequest";
import {PutVisitDetailsRequest} from "./request/PutVisitDetailsRequest";
import {PutPatientDocumentRequest} from "./request/PutPatientDocumentRequest";
import {GetPatientDocumentByIdResponse} from "./response/GetPatientDocumentByIdResponse";
import {GetPatientByIdResponse} from "./response/GetPatientByIdResponse";
import {PutPatientRequest} from "./request/PutPatientRequest";
import {GetPatientNameResponse} from "./response/GetPatientNameResponse";

export default class PatientApi extends ApiBase {

    putVisitDetails = (visitId: number, request: PutVisitDetailsRequest): Promise<AxiosResponse<void>> => {
        return this.axiosInstance.put(
            `${URL.PATIENT_URL}/visit/${visitId}`,
            request,
        );
    }

    postPatientDocument = (request: PostPatientDocumentRequest): Promise<AxiosResponse<number>> => {
        return this.axiosInstance.post(
            `${URL.PATIENT_URL}/document`,
            request,
        );
    }

    putPatientDocument = (documentId: number, request: PutPatientDocumentRequest): Promise<AxiosResponse<void>> => {
        return this.axiosInstance.put(
            `${URL.PATIENT_URL}/document/${documentId}`,
            request,
        );
    }

    getPatientDocumentById = (documentId: number): Promise<AxiosResponse<GetPatientDocumentByIdResponse>> => {
        return this.axiosInstance.get(
            `${URL.PATIENT_URL}/documents/${documentId}`,
        );
    }

    getPatientDocuments = (page: number, size: number, patientId: number):
        Promise<AxiosResponse<GetPatientDocumentsResponse>> => {
        return this.axiosInstance.get(
            `${URL.PATIENT_URL}/${patientId}/documents?page=${page}&size=${size}`,
        );
    }

    deletePatientDocument = (documentId: number):
        Promise<AxiosResponse<void>> => {
        return this.axiosInstance.delete(
            `${URL.PATIENT_URL}/document/${documentId}`,
        );
    }

    postVisit = (patientId: number, request: PostVisitRequest): Promise<AxiosResponse<number>> => {
        return this.axiosInstance.post(
            `${URL.PATIENT_URL}/${patientId}/visit`,
            request,
        );
    }

    getVisits = (page: number, size: number, patientId: number):
        Promise<AxiosResponse<GetPatientVisitsResponse>> => {
        return this.axiosInstance.get(
            `${URL.PATIENT_URL}/${patientId}/visits?page=${page}&size=${size}`,
        );
    }

    getVisitDetails = (visitId: number): Promise<AxiosResponse<GetVisitDetailsResponse>> => {
        return this.axiosInstance.get(
            `${URL.PATIENT_URL}/visits/${visitId}/details`,
        );
    }


    deleteVisit = (visitId: number):
        Promise<AxiosResponse<void>> => {
        return this.axiosInstance.delete(
            `${URL.PATIENT_URL}/visits/${visitId}`,
        )
    }

    getPatientBasicData = (patientId: number): Promise<AxiosResponse<GetPatientCardBasicDataResponse>> => {
        return this.axiosInstance.get(`${URL.PATIENT_URL}/${patientId}/basic/data`);
    }

    getPatientById = (patientId: number): Promise<AxiosResponse<GetPatientByIdResponse>> => {
        return this.axiosInstance.get(`${URL.PATIENT_URL}/${patientId}`);
    }

    postFindPatients = (page: number, size: number, request: PostFindPatientsRequest):
        Promise<PostFindPatientsResponse> => {
        return new Promise<PostFindPatientsResponse>((resolve, reject) => {
            this.axiosInstance.post(
                `${URL.PATIENT_URL}/patients?page=${page}&size=${size}`,
                request)
                .then((resp) => resolve(Object.assign(new PostFindPatientsResponse(), resp.data)))
                .catch((error) => reject(error));
        });
    }

    deletePatient = (patientId: number): Promise<AxiosResponse<void>> => {
        return this.axiosInstance.delete(`${URL.PATIENT_URL}/${patientId}`);
    }

    postPatient = (patientRequest: PostPatientRequest): Promise<AxiosResponse<number>> => {
        return this.axiosInstance.post(
            `${URL.PATIENT_URL}`,
            patientRequest
        );
    }

    putPatient = (patientId: number, putPatientRequest: PutPatientRequest): Promise<AxiosResponse<void>> => {
        return this.axiosInstance.put(
            `${URL.PATIENT_URL}/${patientId}`,
            putPatientRequest,
        );
    }

    getPatientName = (patientId: number): Promise<AxiosResponse<GetPatientNameResponse>> => {
        return this.axiosInstance.get(`${URL.PATIENT_URL}/${patientId}/name`);
    }
}
