import {Property} from "./Property";

/**
 * A type that represents all possible property names used in application.
 * For example herbName is related to name of the herb and allows to get
 * corresponding label for this property and key to retrieve value from response object
 * */
type PropertyKey =
    "herbName" |
    "herbNameLatin" |
    "herbThermalCharacters" |
    "herbWorkingDirection" |
    "herbDailyDose" |
    "herbDailyDoseFrom" |
    "herbDailyDoseTo" |
    "herbStandardDose" |
    "herbBoilingTime" |
    "herbUsingTime" |
    "herbDirectionality" |
    "herbContraindications" |
    "herbToxicology" |
    "herbTolerance" |
    "herbNotes" |
    "herbPhysiological" |
    "herbPregnancy" |
    "herbTastes" |
    "herbCircuits" |
    "herbPharmacological" |
    "herbGroups" |
    "herbActionCenters" |
    "herbCombinations" |
    "patientSearchBoxNameField" |
    "patientSearchBoxSurnameField" |
    "patientSearchBoxAddressField" |
    "patientName" |
    "patientBirthday" |
    "patientCity" |
    "patientEmail" |
    "patientOccupation" |
    "patientBloodType" |
    "patientPostalCode" |
    "patientPregnancy" |
    "patientSex" |
    "patientStreet" |
    "patientStreetNumber" |
    "patientSurname" |
    "patientTelephone" |
    "visitSyndrome" |
    "visitAilments" |
    "visitDiagnosis" |
    "visitTests" |
    "visitDate" |
    "pulseNote" |
    "rightCun" |
    "leftCun" |
    "rightGuan" |
    "leftGuan" |
    "rightChi" |
    "leftChi" |
    "patientDocumentText" |
    "patientDocumentTitle" |
    "medicineListIndex" |
    "medicineListHerbName" |
    "medicineListBoilingTime" |
    "medicineListDose" |
    "herbDetailsViewBasicData" |
    "herbDetailsViewRemaining";

/**
 * A map-like object that contains all labels and value keys for objects used as DTOs
 * for example we can access specific property of api response by using response[properties.property.valueKey]
 * */
export const properties: Record<PropertyKey, Property> = {
    herbName: {label: 'Nazwa zioła', key: 'name'},
    herbNameLatin: {label: 'Łacińska nazwa zioła', key: 'nameLatin'},
    herbThermalCharacters: {label: 'Charakter termiczny', key: 'herbThermalCharacters'},
    herbWorkingDirection: {label: 'Kierunek działania', key: 'workingDirection'},
    herbDailyDose: {label: "Dawka dzienna", key: null},
    herbDailyDoseFrom: {label: 'Od', key: "dailyDoseFrom"},
    herbDailyDoseTo: {label: 'Do', key: "dailyDoseTo"},
    herbStandardDose: {label: 'Dawka standardowa', key: "standardDose"},
    herbBoilingTime: {label: 'Czas gotowania', key: "boilingTime"},
    herbUsingTime: {label: 'Czas stosowania', key: "usingTime"},
    herbDirectionality: {label: 'Kierunkowość zioła', key: "directionality"},
    herbContraindications: {label: 'Przeciwwskazania', key: "contraindications"},
    herbToxicology: {label: 'Toksykologia', key: "toxicology"},
    herbTolerance: {label: 'Tolerancja', key: "tolerance"},
    herbNotes: {label: 'Notatki', key: "notes"},
    herbPhysiological: {label: 'Działania fizjologiczne', key: "physiological"},
    herbPregnancy: {label: 'Ciąża', key: "pregnancy"},
    herbTastes: {label: 'Smak', key: "herbTastes"},
    herbCircuits: {label: 'Przynależność do obiegu czynnościowego', key: "herbCircuits"},
    herbPharmacological: {label: 'Działania farmakologiczne', key: "pharmacological"},
    herbGroups: {label: 'Grupa', key: "herbGroups"},
    herbActionCenters: {label: 'Ośrodek działania', key: "herbActionCenters"},
    herbCombinations: {label: 'Kombinacje ziół', key: "combinations"},
    patientSearchBoxNameField: {label: 'Imię', key: "name"},
    patientSearchBoxSurnameField: {label: 'Nazwisko', key: "surname"},
    patientSearchBoxAddressField: {label: 'Adres', key: "address"},
    patientName: {label: "Imię", key: "name"},
    patientBirthday: {label: 'Data urodzenia', key: "birthday"},
    patientCity: {label: 'Miasto', key: "city"},
    patientEmail: {label: 'Adres email', key: "email"},
    patientOccupation: {label: 'Zawód', key: "occupation"},
    patientBloodType: {label: 'Grupa Krwi', key: "bloodType"},
    patientPostalCode: {label: 'Kod pocztowy', key: "postalCode"},
    patientPregnancy: {label: 'Ciąża', key: "pregnant"},
    patientSex: {label: 'Płeć', key: "sex"},
    patientStreet: {label: 'Ulica', key: "street"},
    patientStreetNumber: {label: 'Numer ulicy', key: "streetNumber"},
    patientSurname: {label: 'Nazwisko', key: "surname"},
    patientTelephone: {label: 'Numer telefonu', key: "telephone"},
    visitSyndrome: {label: 'Syndrom', key: "syndrome"},
    visitDiagnosis: {label: 'Diagnoza z języka/brzucha', key: "diagnosis"},
    visitAilments: {label: 'Dolegliwości', key: "ailments"},
    visitTests: {label: 'Badania', key: "tests"},
    visitDate: {label: 'Termin wizyty', key: "date"},
    pulseNote: {label: 'Notatka', key: "note"},
    rightCun: {label: 'Prawy CUN', key: "rightCun"},
    leftCun: {label: 'Lewy CUN', key: "leftCun"},
    rightGuan: {label: 'Prawy GUAN', key: "rightGuan"},
    leftGuan: {label: 'Lewy GUAN', key: "leftGuan"},
    rightChi: {label: 'Prawy CHI', key: "rightChi"},
    leftChi: {label: 'Lewy CHI', key: "leftChi"},
    patientDocumentText: {label: 'Treść dokumentu', key: "text"},
    patientDocumentTitle: {label: 'Tytuł', key: "title"},
    medicineListIndex: {label: 'Nr.', key: null},
    medicineListHerbName: {label: 'Nazwa zioła', key: "name"},
    medicineListBoilingTime: {label: 'Czas gotowania', key: "boilingTime"},
    medicineListDose: {label: 'Dawka', key: "dose"},
    herbDetailsViewBasicData: {label: "Dane podstawowe", key: null},
    herbDetailsViewRemaining: {label: "Pozostałe", key: null},
}
