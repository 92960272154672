import React, {createContext, Dispatch, useReducer} from "react";
import {JwtResponse} from "../../auth/api/response/JwtResponse";

type Action = {
    type: "update";
    value: JwtResponse;
} | {
    type: "clear"
};

type Props = {
    children: JSX.Element;
};

const USER_KEY = "user";

const initialState = (): JwtResponse => {
    const saved: JwtResponse = JSON.parse(localStorage.getItem(USER_KEY));
    return saved !== null ? saved : null;
}

const initialDispatch: Dispatch<Action> = () => initialState();

const LocalStorageContext = createContext({
    user: initialState(),
    dispatch: initialDispatch,
});

let usernameChangeListener: (username: string) => void;

const reducer = (state: JwtResponse, action: Action): JwtResponse => {
    switch (action.type) {
        case "clear":
            localStorage.clear();
            return null;
        case "update":
            localStorage.setItem(USER_KEY, JSON.stringify(action.value));
            return {...state, ...action.value} as JwtResponse;
    }
}

const LocalStorageProvider = ({children}: Props) => {
    const [user, dispatch] = useReducer(reducer, initialState());

    return <LocalStorageContext.Provider value={{user, dispatch}}>{children}</LocalStorageContext.Provider>
};

const addUsernameChangeListener = (userNameListener: (username: string) => void) => {
    usernameChangeListener = userNameListener;
};

export {LocalStorageProvider, LocalStorageContext, addUsernameChangeListener}
