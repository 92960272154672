import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import {RoutePaths} from '../constants/routePaths'
import {toast} from 'react-toastify';
import {NavigateFunction} from "react-router-dom";
import {getTokenFromStorage} from "../utils/utils";

const applyAuthorization = (requestConfig: AxiosRequestConfig) => {
    let headers: any = {'Authorization': getTokenFromStorage(), ...requestConfig.headers};
    let authorizedConfig: AxiosRequestConfig = {
        ...requestConfig,
        headers
    };

    return authorizedConfig;
};

export default class ApiBase {

    protected readonly defaultRequestConfig: AxiosRequestConfig = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Accept-Language': 'pl-PL'
        }
    };

    protected readonly uploadImageRequestConfig: AxiosRequestConfig = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            // 'Content-Type': undefined
        }
    };

    protected readonly getImageFileConfig: AxiosRequestConfig = {
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        responseType: "arraybuffer"
    }

    protected axiosInstance: AxiosInstance;

    constructor(navigate: NavigateFunction, clearUser: () => void) {
        this.axiosInstance = axios.create();

        this.axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            toast.error("Sesja wygasła, zaloguj się");
                            clearUser();
                            navigate(RoutePaths.login.path);
                            break;
                        case 404:
                            navigate(RoutePaths.landing.path);
                            toast.error("Wybrany zasób nie istnieje");
                            break;
                        case 400:
                            toast.error(error.response.data.message);
                    }
                } else {
                    toast.error("Brak połączenia z internetem");
                    clearUser()
                    navigate(RoutePaths.login.path);
                }

                return Promise.reject(error);
            })

        this.axiosInstance.interceptors.request.use(
            config => {
                if (config != null) {
                    return applyAuthorization(config);
                }

                return applyAuthorization(this.defaultRequestConfig);
            },
        )
    }
}
