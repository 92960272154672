import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import useConfirm from "../../hooks/useConfirm";


function ConfirmDialog() {
    const {onConfirm, onCancel, confirmState} = useConfirm();

    return (
        <Dialog
            open={confirmState.isVisible}
            onClose={() => onCancel()}
        >
            <DialogTitle id="delete-dialog-title">{"Potwierdź"}</DialogTitle>
            <DialogContent>
                <DialogContentText id={"delete-dialog-description"}>
                    {confirmState.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()}
                        color={"primary"}>
                    Anuluj
                </Button>
                <Button onClick={() => onConfirm()}
                        color={"primary"}>
                    Potwierdź
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;
