import React, {useContext, useEffect, useReducer} from "react";
import {Api, ApiContext} from "../../common/api";
import {AxiosResponse} from "axios";
import PracticePrescriptionList from "./PracticePrescriptionList";
import PrescriptionFilterResponse from "../api/response/PrescriptionFilterResponse";
import {PostPrescriptionFilterRequest} from "../api/request/PostPrescriptionFilterRequest";
import {PrescriptionType} from "../api";
import {Prescription} from "../api/response/Prescription";
import {makeStyles} from "tss-react/mui";
import {PrescriptionActions} from "../actions/PrescriptionActions";

const useStyles = makeStyles()((theme) => ({
    prescriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
    },
}));

const defaultState: State = {
    currentPage: 0,
    currentPageSize: 100,
    filterState: {prescriptionType: PrescriptionType.PRACTICE} as PostPrescriptionFilterRequest,
    filterResponse: new PrescriptionFilterResponse([], 0, 0, 0),
}

type State = {
    filterState: PostPrescriptionFilterRequest;
    filterResponse: PrescriptionFilterResponse;
    currentPage: number;
    currentPageSize: number;
}

const reducer = (state: State, action: PrescriptionActions): State => {
    switch (action.type) {
        case "setFilterDto":
            return {...state, filterState: action.value};
        case "setFilterResponse":
            return {...state, filterResponse: action.value}
        case "changeListData":
            state.filterResponse.content = action.value;
            return {...state, filterResponse: state.filterResponse}
        case "setCurrentPage":
            return {...state, currentPage: action.value};
        case "setCurrentPageSize":
            return {...state, currentPageSize: action.value};
    }
}

export default function PrescriptionListPracticeView() {
    const {classes} = useStyles();
    const [state, dispatchState] = useReducer(reducer, defaultState);
    const {prescriptionApi} = useContext<Api>(ApiContext)

    useEffect(() => {
        prescriptionApi.postFilterPrescriptions(state.currentPage, state.currentPageSize, state.filterState)
            .then((resp: AxiosResponse<PrescriptionFilterResponse>) => {
                dispatchState({type: "setFilterResponse", value: resp.data});
            }).catch(() => {}
        );
    }, [prescriptionApi, state.currentPage, state.currentPageSize, state.filterState])

    const handleListDataChange = (listData: Prescription[]) => {
        dispatchState({type: "changeListData", value: listData});
    }

    return (
        <div className={classes.prescriptionContainer}>
            <PracticePrescriptionList listData={state.filterResponse}
                                      listDataChangedHandler={handleListDataChange}/>
        </div>
    );
}
