import {makeStyles} from "tss-react/mui";
import {Box, ImageList, ImageListItem} from "@mui/material";
import VisitImage from "./VisitImage";
import ExpandImageDialog from "./ExpandImageDialog";
import {useState} from "react";

const IMAGE_WIDTH = 256;
const IMAGE_HEIGHT = 192;

const useStyles = makeStyles()((theme) => ({
    listContainer: {
        display: "flex",
        justifyContent: "center"
    },
    listItem: {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
    },
    image: {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
    }
}));

type Props = {
    imageIds: number[];
}

type State = {
    clickedFile: File,
    open: boolean,
}

export default function VisitReadonlyGallery({imageIds}: Props) {
    const {classes} = useStyles();
    const [state, setState] = useState<State>({clickedFile: null, open: false});

    const onClose = () => {
        setState({clickedFile: null, open: false});
    }

    const onItemClick = (imageFile: File) => {
        setState({clickedFile: imageFile, open: true});
    }

    return (
        <Box className={classes.listContainer}>
            <ExpandImageDialog open={state.open} onClose={onClose} imageFile={state.clickedFile}/>
            <ImageList cols={4}>
                {imageIds.map((imageId) => (
                    <ImageListItem key={imageId} className={classes.listItem}>
                        <VisitImage imageId={imageId} className={classes.image} onClickHandler={onItemClick}/>
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    )
}
