import React, {useCallback, useContext, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import {useNavigate} from "react-router-dom";
import {Api, ApiContext} from "../../../common/api";
import {RoutePaths} from "../../../common/constants/routePaths";
import AppInfiniteLoadingList from "../../../common/components/AppInfiniteLoadingList";
import VisitListItemView from "./VisitListItemView";
import VisitViewDialog from "./VisitViewDialog";
import {useSubListStyles} from "../../../common/styles/subListStyles";

type Props = {
    patientId: number;
}

type OpenItemDialogState = {
    visitId: number;
    isOpen: boolean;
}

export default function PatientCardVisitsList({patientId}: Props) {
    const {classes} = useSubListStyles();
    const navigate = useNavigate();
    const [itemDialogState, setItemDialogState] = useState<OpenItemDialogState>({
        isOpen: false,
        visitId: undefined
    })
    const {patientApi} = useContext<Api>(ApiContext);

    const handleAddClick = () => {
        navigate(RoutePaths.createVisit.getPath([patientId]));
    }

    const handleOpenItem = useCallback((visitId: number) => {
        setItemDialogState({isOpen: true, visitId: visitId});
    }, [])

    const handleCloseItem = useCallback(() => {
        setItemDialogState({isOpen: false, visitId: undefined});
    }, [])

    return (
        <Box className={classes.container}>
            <VisitViewDialog isOpen={itemDialogState.isOpen}
                             handleClose={handleCloseItem}
                             visitId={itemDialogState.visitId}/>
            <Box className={classes.header}>
                <Typography variant="h5">Wizyty Pacjenta</Typography>
                <IconButton size="large" onClick={() => handleAddClick()}>
                    <AddBoxRoundedIcon color={"primary"}/>
                </IconButton>
            </Box>
            <Box className={classes.body}>
                <AppInfiniteLoadingList requestParams={patientId}
                                        openItemHandler={handleOpenItem}
                                        apiFetchPaginatedItems={patientApi.getVisits}
                                        ListItemComponent={VisitListItemView}
                                        apiDeleteItem={patientApi.deleteVisit}/>
            </Box>
        </Box>
    );
}
