import {Dialog} from "@mui/material";
import AppImage from "./AppImage";

type Props = {
    open: boolean;
    onClose: () => void;
    imageFile: File;
}

export default function ExpandImageDialog({open, onClose, imageFile}: Props) {
    return (
        <Dialog onClose={onClose} open={open}>
            <AppImage alt={"empty.png"}
                      file={imageFile}
            />
        </Dialog>
    );
}
