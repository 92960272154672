import {useEffect, useState} from "react";

type Props = {
    key?: string | number;
    src?: string;
    file?: File,
    alt: string,
    className?: string
    onError?: (event: any) => void;
    onClick?: (imageFile: File) => void;
}

export default function AppImage(props: Props) {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        if (props.file) {
            let url = URL.createObjectURL(props.file)
            setImageUrl(url);

            return () => {
                URL.revokeObjectURL(url);
            }
        }
    }, [props.file])

    return (
        <img key={props.key}
             className={props.className}
             src={props.file ? imageUrl : props.src}
             onError={props.onError}
             alt={props.alt}
             loading="lazy"
             onClick={() => props.onClick(props.file)}
        />
    )
}
