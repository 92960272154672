import Visit from "./Visit";
import {emptyVisitData, mapPulse, VisitData, visitReducer} from "../../reducers/visitReducer";
import {useCallback, useContext, useReducer} from "react";
import {Api, ApiContext} from "../../../common/api";
import {PostVisitRequest} from "../../api/request/PostVIsitRequest";
import {PostPrescriptionRequest} from "../../../prescription/api/request/PostPrescriptionRequest";
import {useNavigate, useParams} from "react-router-dom";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";

const mapPostVisitRequest = (visitData: VisitData): PostVisitRequest => {
    const prescriptionRequests: PostPrescriptionRequest[] =
        visitData.prescriptions.map((item) => {
            return {
                herbProcessing: item.herbProcessing,
                medicineList: item.medicineList,
                nameSurname: item.nameSurname
            }
        })

    return {
        date: visitData.basicData.date,
        syndrome: visitData.basicData.syndrome,
        diagnosis: visitData.basicData.diagnosis,
        ailments: visitData.basicData.ailments,
        tests: visitData.basicData.tests,
        pulse: mapPulse(visitData),
        prescriptions: prescriptionRequests
    }
}

export function CreateVisit() {
    const {patientApi, imagesApi} = useContext<Api>(ApiContext)
    const navigate = useNavigate();
    const [visit, dispatchVisitData] = useReducer(visitReducer, emptyVisitData)
    const {patientId} = useParams<"patientId">();

    const cancel = useCallback(async () => {
        navigate(-1);
    }, []);

    const save = async () => {
        patientApi.postVisit(Number(patientId), mapPostVisitRequest(visit))
            .then((resp: AxiosResponse<number>) => {
                if (saveImages(resp.data)) {
                    toast.success("Utworzono wizytę");
                    navigate(-1);
                }
            }).catch(() => {
        });
    };

    const saveImages = (visitId: number): boolean => {
        const filesToSave: File[] = visit.visitImages.newImages.map(newImage => newImage.file);
        let success = true;

        if (filesToSave.length > 0) {
            imagesApi.postVisitImages(visitId, filesToSave)
                .then(() => {
                })
                .catch(() => {
                    success = false;
                });
        }

        return success;
    }

    return (
        <Visit visitData={visit}
               handleCancelClick={cancel}
               handleSaveClick={save}
               visitDispatcher={dispatchVisitData}/>
    )
}