import React from 'react';
import {HerbsApi} from "../../herbs/api";
import {PrescriptionApi} from "../../prescription/api";
import AuthApi from "../../auth/api/AuthApi";
import PatientApi from "../../patient/api/PatientApi";
import ImagesApi from "../../images/api/ImagesApi";

const ApiContext = React.createContext(null);

export interface Api {
    herbsApi: HerbsApi;
    prescriptionApi: PrescriptionApi;
    authApi: AuthApi;
    imagesApi: ImagesApi;
    patientApi: PatientApi;
}

export const withApi = (Component: any) => (props: any) => (
    <ApiContext.Consumer>
        {(value: Api) => <Component {...props}
                                    herbApi={value.herbsApi}
                                    prescriptionApi={value.prescriptionApi}
                                    authApi={value.authApi}
                                    imagesApi={value.imagesApi}
                                    patientApi={value.patientApi}
        />}
    </ApiContext.Consumer>
);

export default ApiContext;
