import React, {memo} from "react";
import {TextField} from "@mui/material";
import {Property} from "../constants/Property";

export type OnChangeTexFieldPayload = {
    fieldName: string,
    fieldValue: string
}

type Props = {
    property: Property;
    value: string | boolean | Date;
    onChange: (value: OnChangeTexFieldPayload) => void;
    className: string;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
    multiline?: boolean;
    isReadOnly?: boolean;
}

const areEqual = (previousProps: Props, nextProps: Props) => {
    return previousProps.property === nextProps.property
        && previousProps.value === nextProps.value;
}

function AppTextField(
    {
        property,
        value,
        onChange,
        className,
        multiline = true,
        isReadOnly = false,
        ...other
    }: Props) {

    return (
        <TextField {...other}
                   id={property.label}
                   multiline={multiline}
                   variant="outlined"
                   className={className}
                   InputProps={{
                       readOnly: isReadOnly,
                   }}
                   name={property.key}
                   value={value ? value : ""}
                   label={property.label}
                   onChange={(event => {
                       event.preventDefault();
                       onChange({
                           fieldName: event.target.name,
                           fieldValue: event.target.value
                       })
                   })}
        />
    )
}

export default memo(AppTextField, areEqual);
