import {Dialog, DialogContent} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import AppDialogTitle from "../../../common/components/dialogs/AppDialogTitle";
import Visit from "../visit/Visit";
import {useContext, useEffect, useState} from "react";
import {Api, ApiContext} from "../../../common/api";
import {AxiosResponse} from "axios";
import {GetVisitDetailsResponse} from "../../api/response/GetVisitDetailsResponse";
import {emptyVisitData, mapVisitData} from "../../reducers/visitReducer";
import {Dayjs} from "dayjs";

const useStyles = makeStyles()((theme) => ({
    content: {
        minHeight: "85vmin",
        maxHeight: "100vmax",
    }
}));

type Props = {
    isOpen: boolean;
    handleClose: () => void
    visitId: number;
}

const visitDialogTitle = (name: string, surname: string, date: Dayjs | null) => {
    return name + " " + surname + ": " + "Wizyta z dnia " + date;
}

export default function VisitViewDialog(props: Props) {
    const {classes} = useStyles();
    const [visitData, setVisitData] = useState(emptyVisitData)
    const [title, setTitle] = useState("");
    const {patientApi} = useContext<Api>(ApiContext);

    useEffect(() => {
        if (props.visitId) {
            patientApi.getVisitDetails(props.visitId)
                .then((resp: AxiosResponse<GetVisitDetailsResponse>) => {
                    setVisitData(mapVisitData(resp.data));
                    setTitle(visitDialogTitle(
                        resp.data.patientName,
                        resp.data.patientSurname,
                        resp.data.date));
                }).catch(() => {
            });
        }
    }, [props.visitId, props.isOpen])

    return (
        <Dialog open={props.isOpen}
                scroll="paper"
                fullWidth
                maxWidth="lg">
            <AppDialogTitle onClose={props.handleClose}>
                {title}
            </AppDialogTitle>
            <DialogContent dividers className={classes.content}>
                <Visit visitData={visitData}
                       isReadOnly={true}
                />
            </DialogContent>
        </Dialog>
    )
}
