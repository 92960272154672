import {GetPatientVisitsResponseItem} from "../../api/response/GetPatientVisitsResponseItem";
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Box, Button} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {InfiniteListItemProps} from "../../../common/components/AppInfiniteLoadingList";
import {useNavigate, useParams} from "react-router-dom";
import {RoutePaths} from "../../../common/constants/routePaths";
import {useListItemViewStyle} from "./styles";
import {properties} from "../../../common/constants/properties";

export default function VisitListItemView(
    {
        deleteHandler,
        openItemHandler,
        data,
        lastItemRef
    }: InfiniteListItemProps<GetPatientVisitsResponseItem>) {
    const {classes} = useListItemViewStyle();
    const {patientId} = useParams<"patientId">();
    const navigate = useNavigate();

    return (
        <Paper {...{ref: lastItemRef} as any} variant={"outlined"} className={classes.root}>
            <Box className={classes.headerBox}>
                <Box>
                    <Typography color={"GrayText"}>{properties.visitDate.label}: </Typography>
                    <Typography className={classes.date}>{data.date?.toString()}</Typography>
                </Box>
                <Box className={classes.prescriptionCount}>
                    <Typography>{data.prescriptionCount}</Typography>
                    <AssignmentIcon color={"primary"}/>
                </Box>
            </Box>
            <Divider/>
            <Box className={classes.syndromeBox}>
                <Typography color={"GrayText"}>Syndrom: </Typography>
                <Typography className={classes.syndrome}>{data.syndrome}</Typography>
            </Box>
            <Box className={classes.buttonBox}>
                <Button className={classes.button}
                        onClick={() => deleteHandler(data.id)}
                        color={"primary"}
                        size={"small"}>
                    Usuń
                </Button>
                <Button className={classes.button}
                        color={"primary"}
                        onClick={() => navigate(RoutePaths.editVisit.getPath([patientId, data.id]))}
                        size={"small"}>
                    Edytuj
                </Button>
                <Button className={classes.button}
                        color={"primary"}
                        size={"small"}
                        onClick={() => openItemHandler(data.id)}>
                    Otwórz
                </Button>
            </Box>
        </Paper>
    )
}
