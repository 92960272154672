import {PostFindPatientsRequest} from "../../api/request/PostFindPatientsRequest";
import {useState} from "react";
import PatientListSearchBox from "./PatientListSearchBox";
import PatientList from "./PatientList";

const defaultSearchParams: PostFindPatientsRequest = {
    name: '',
    surname: '',
    address: '',
};

export default function PatientBrowser() {
    const [searchParams, setSearchParams] = useState(defaultSearchParams);

    const onSearchParamsChange = (params: PostFindPatientsRequest): void => {
        setSearchParams(params);
    }

    return (
        <div>
            <PatientListSearchBox onSearchParamsChange={onSearchParamsChange} params={searchParams}/>
            <PatientList searchParams={searchParams}/>
        </div>
    );
}
