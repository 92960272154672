import ApiBase from "../../common/api/ApiBase";
import {HerbData} from "./HerbData";
import {AxiosResponse} from "axios";
import * as URL from "../../common/constants/endpoints";
import {PostSearchHerbsRequest} from "./request/PostSearchHerbsRequest";
import {Option} from "./Option";
import PostSearchHerbsResponse from "./response/PostSearchHerbsResponse";
import {PostFindAutocompleteNameRequest} from "./request/PostFindAutocompleteNameRequest";
import {PostFilterOptionsRequest} from "./request/PostFilterOptionsRequest";
import {PostHerbRequest} from "./request/PostHerbRequest";
import {GetHerbInMedicineDataResponse} from "./response/GetHerbInMedicineDataResponse";

export default class HerbsApi extends ApiBase {

    postHerb = (request: PostHerbRequest): Promise<AxiosResponse<number>> => {
        return this.axiosInstance.post(URL.HERB_URL,
            request);
    };

    putHerb = (herb: HerbData): Promise<AxiosResponse<number>> => {
        return this.axiosInstance.put(URL.HERB_URL,
            herb);
    }

    postFilterHerb = (page: number, size: number, filterDto: PostSearchHerbsRequest):
        Promise<AxiosResponse<PostSearchHerbsResponse>> => {

        return this.axiosInstance.post(
            `${URL.HERB_URL}filter?page=${page}&size=${size}`,
            filterDto);
    };

    getHerbById = (herbId: number): Promise<AxiosResponse<HerbData>> => {
        return this.axiosInstance.get(`${URL.HERB_URL}${herbId}`);
    }

    deleteHerb = (herbId: number): Promise<AxiosResponse<void>> => {
        return this.axiosInstance.delete(`${URL.HERB_URL}${herbId}`);
    }

    postHerbNameAutocompleteOptions = (request: PostFindAutocompleteNameRequest): Promise<AxiosResponse<Option[]>> => {
        return this.axiosInstance.post(
            `${URL.HERB_URL}names`,
            request);
    };

    filterOptions = (request: PostFilterOptionsRequest): Promise<AxiosResponse<Option[]>> => {
        return this.axiosInstance.post(
            `${URL.OPTION_URL}filter`,
            request);
    }

    getHerbInMedicineData = (herbId: number): Promise<AxiosResponse<GetHerbInMedicineDataResponse>> => {
        return this.axiosInstance.get(
            `${URL.HERB_URL}${herbId}/medicine`);
    }
}
