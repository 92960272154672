import {useContext} from "react";
import {JwtResponse} from "../../auth/api/response/JwtResponse";
import {LocalStorageContext} from "../store";


export default function useJwtUser() {
    const {user, dispatch} = useContext(LocalStorageContext);

    const clearUser = (): void => {
        dispatch({type: "clear"});
    }

    const updateUser = (jwtUser: JwtResponse): void => {
        dispatch({type: "update", value: jwtUser});
    }

    return {user, clearUser, updateUser}
}
