import {useCallback, useContext} from "react";
import {ConfirmContext} from "../store/ConfirmContext";

let resolvePromise: (value: boolean | PromiseLike<boolean>) => void;
export default function useConfirm() {
    const {confirmState, dispatch} = useContext(ConfirmContext);

    const onConfirm = useCallback(() => {
        closeConfirm();
        resolvePromise(true);
    }, []);

    const onCancel = useCallback(() => {
        closeConfirm();
        resolvePromise(false);
    }, []);

    const confirm = useCallback((text: string) => {
        dispatch({
            type: "SHOW_CONFIRM",
            payload: {
                text: text,
            }
        })

        return new Promise<boolean>((resolve, reject) => {
            resolvePromise = resolve;
        })
    }, []);

    const closeConfirm = useCallback(() => {
        dispatch({
            type: "HIDE_CONFIRM",
        })
    }, [])

    const withConfirm = useCallback(async (toCall: () => void, message: string) => {
        const isConfirmed = await confirm(message);

        if (isConfirmed) {
            toCall();
        }
    }, []);

    return {withConfirm, onConfirm, onCancel, confirmState};
};