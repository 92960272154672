import {Prescription} from "../api/response/Prescription";
import List from "@mui/material/List";
import MedicineReadOnlyListItem from "./MedicineReadOnlyListItem";

type Props = {
    data: Prescription[];
}

export default function PatientPrescriptionReadOnlyList({data}: Props) {
    return (
        <List>
            {data.map(
                (prescription, index) => {
                    return (
                        <MedicineReadOnlyListItem key={index} data={prescription}/>
                    )
                }
            )}
        </List>
    )
}
