import {useContext} from "react";
import {Api, ApiContext} from "../../common/api";
import {useNavigate} from "react-router-dom";
import {PostPatientRequest} from "../api/request/PostPatientRequest";
import {toast} from "react-toastify";
import {RoutePaths} from "../../common/constants/routePaths";
import Patient from "./Patient";

export default function CreatePatient() {
    const navigate = useNavigate();
    const {patientApi} = useContext<Api>(ApiContext);

    const handleCancel = async () => {
        navigate(-1);
    };

    const handleSave = async (request: PostPatientRequest) => {
        patientApi.postPatient(request)
            .then(() => {
                toast.success("Zapisano dane pacjenta");
                navigate(RoutePaths.patients.getPath());
            }).catch(() => {
        });
    };

    return (
        <Patient handleCancel={handleCancel}
                 handleSave={handleSave}
        />
    )
};
