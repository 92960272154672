import React, {useRef, useState} from 'react';
import {makeStyles} from "tss-react/mui";
import AppImage from "./AppImage";

const useStyles = makeStyles()(theme => ({
    dropZone: {
        backgroundColor: 'white',
        width: '128px',
        height: '128px',
        border: '2px dashed rgb(187, 186, 186)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontSize: '16px',
    },
    icon: {
        opacity: 0.3,
        height: '64px',
        width: '64px',
    },
    fileInput: {
        display: 'none',
    },
    highlight: {
        backgroundColor: 'rgb(188, 185, 236)',
    },
}));

type Props = {
    onFilesSelected: (files: File[]) => void | null | undefined;
    disabled?: boolean;
    isMultiple?: boolean;
};

export default function DropZone({onFilesSelected, disabled, isMultiple}: Props) {
    const [highlight, setHighlight] = useState(false);
    const {classes} = useStyles();
    const fileInputRef = useRef(null);

    function onSelectFiles(event: any) {
        if (disabled) return;
        const files = event.target.files;
        if (onFilesSelected) {
            onFilesSelected(files);
        }
    }

    function openFileDialog() {
        if (disabled) return;
        return fileInputRef.current.click();
    }

    const onDragOver = (event: any) => {
        event.preventDefault();
        if (disabled) return;
        setHighlight(true);
    }

    function onDragLeave() {
        setHighlight(false);
    }

    function onDrop(event: any) {
        event.preventDefault();
        if (disabled) return;
        const files = event.dataTransfer.files;
        if (onFilesSelected) {
            onFilesSelected(Array.from(files));
        }
        setHighlight(false);
    }

    return (
        <div
            className={[classes.dropZone, highlight ? classes.highlight : ''].join(' ')}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onClick={openFileDialog}
            style={{cursor: disabled ? "default" : "pointer"}}
        >
            <AppImage
                alt="upload"
                className={classes.icon}
                src="cloud_upload-24px.svg"
            />

            <input
                id={"dropzone"}
                ref={fileInputRef}
                className={classes.fileInput}
                type="file"
                multiple={isMultiple ? isMultiple : true}
                onChange={onSelectFiles}
            />
        </div>
    );
}
