import {makeStyles} from "tss-react/mui";

export const useDocumentListItemViewStyle = makeStyles()((theme) => ({
    root: {
        borderRadius: 20
    },
    buttonBox: {
        width: "100%",
        alignSelf: "flex-end",
        marginRight: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        margin: theme.spacing(1),
        borderRadius: 20
    },
    headerBox: {
        margin: theme.spacing(1),
    },
    documentText: {
        margin: theme.spacing(1),
        float: "left",
        textAlign: "left"
    }
}));
