import React from "react";
import {Card} from "@mui/material";
import {GetPatientCardBasicDataResponse} from "../../api/response/GetPatientCardBasicDataResponse";
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {usePatientCardBasicDataViewStyle} from "./styles";

type Props = {
    data: GetPatientCardBasicDataResponse;
}

export default function PatientCardBasicDataView({data}: Props) {
    const {classes} = usePatientCardBasicDataViewStyle();

    const pregnancyText = (): string => {
        if (data.pregnant) {
            return "Pacjentka jest w ciąży";
        }
    }

    return (
        <Card className={classes.root}>
            <Box className={classes.firstRow}>
                <Typography color="textSecondary" variant="h2">{data.name} {data.surname}</Typography>
                {data.pregnant &&
                    <Typography color="textSecondary" variant="h4" className={classes.iconTypography}>
                        <PregnantWomanIcon/>
                        {pregnancyText()}
                    </Typography>
                }
            </Box>
            <Box className={classes.secondRow}>
                <Typography color="textSecondary" variant="h5" className={classes.iconTypography}>
                    <EmailIcon/>
                    {data.email}
                </Typography>
                <Typography color="textSecondary" variant="h5" className={classes.iconTypography}>
                    <CakeIcon/>
                    {data.birthday?.toString()}
                </Typography>
                <Typography color="textSecondary" variant="h5" className={classes.iconTypography}>
                    <WorkIcon/>
                    {data.occupation}
                </Typography>
            </Box>
        </Card>
    )
}
