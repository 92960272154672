import React, {memo, useContext, useState} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import {TextField} from "@mui/material";
import {Option} from "../api/Option";
import {Api, ApiContext} from "../../common/api";
import {AxiosResponse} from "axios";
import {makeStyles} from "tss-react/mui";
import {OptionKey} from "../api/OptionKey";
import {Property} from "../../common/constants/Property";

const useStyles = makeStyles()((theme) => {
    return {
        textField: {
            width: '100%',
            margin: theme.spacing(1),
        },
    };
});

type Props = {
    property: Property;
    optionKey: OptionKey;
    value: Option[];
    onChange: any;
    className?: string;
};

const areEqual = (previousProps: Props, nextProps: Props) => {
    return previousProps.property === nextProps.property
        && previousProps.value === nextProps.value
}

function HerbChipsField({optionKey, property, value, onChange, className}: Props) {
    const {classes} = useStyles();
    const {herbsApi} = useContext<Api>(ApiContext);
    const [options, setOptions] = useState([] as Option[]);

    const onInputChange = (value: string): void => {
        herbsApi.filterOptions({searchString: value, optionKey: optionKey})
            .then((res: AxiosResponse<Option[]>) => {
                setOptions(res.data);
            })
            .catch(() => {
            })
    }

    return (
        <Autocomplete multiple
                      freeSolo
                      value={value}
                      options={options}
                      getOptionLabel={option => typeof option === 'string' ? option : option.label}
                      onInputChange={(event, value) => onInputChange(value)}
                      onChange={((event, value) => {
                          onChange(property.key, value)
                      })}
                      renderInput={(params) =>
                          (<TextField {...params}
                                      multiline
                                      helperText={"Potwierdź wprowadzenie enterem"}
                                      id={property.label}
                                      variant="outlined"
                                      name={property.key}
                                      label={property.label}
                                      className={classes.textField}
                              />
                          )}/>
    )
}

export default memo(HerbChipsField, areEqual);
