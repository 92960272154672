import {Api, ApiContext} from "../../api";
import {HerbsApi} from "../../../herbs/api";
import {PrescriptionApi} from "../../../prescription/api";
import {AuthApi} from "../../../auth/api";
import ImagesApi from "../../../images/api/ImagesApi";
import PatientApi from "../../../patient/api/PatientApi";
import {makeStyles} from "tss-react/mui";
import {Outlet, useNavigate} from "react-router-dom";
import useJwtUser from "../../hooks/useJwtUser";
import React from "react";
import {Container} from "@mui/material";
import Navigation from "../navigation/Navigation";

const useStyles = makeStyles()({
    AppContainer: {
        overflow: 'hidden',
        textAlign: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
    '@global': {
        'html, body': {
            height: '100%',
            minHeight: '100%:',
        },
        '#root': {
            height: '100%',
            minHeight: '100%',
        },
    }
});

export default function Layout() {
    const {classes} = useStyles();
    const navigate = useNavigate()
    const {clearUser, user} = useJwtUser();

    const initApi = (): Api => {
        return {
            herbsApi: new HerbsApi(navigate, clearUser),
            prescriptionApi: new PrescriptionApi(navigate, clearUser),
            authApi: new AuthApi(navigate, clearUser),
            imagesApi: new ImagesApi(navigate, clearUser),
            patientApi: new PatientApi(navigate, clearUser)
        }
    };

    return (
        <ApiContext.Provider value={initApi()}>
            {user && <Navigation/>}
            <Container maxWidth={false} className={classes.AppContainer}>
                <Outlet/>
            </Container>
        </ApiContext.Provider>
    )
};