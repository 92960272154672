import React, {useContext, useState} from "react";
import {Typography} from "@mui/material";
import {Api, ApiContext} from "../../common/api";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {Navigate, useNavigate} from "react-router-dom";
import {AxiosResponse} from "axios";
import {JwtResponse} from "../api/response/JwtResponse";
import {RoutePaths} from '../../common/constants/routePaths'
import {toast} from "react-toastify";
import {makeStyles} from "tss-react/mui";
import useJwtUser from "../../common/hooks/useJwtUser";

const useStyles = makeStyles()((theme) => ({
    Container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        marginTop: theme.spacing(12),
    },
    LoginForm: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            width: "90%",
        },
        [theme.breakpoints.up('md')]: {
            width: "60%",
        },
        [theme.breakpoints.up('lg')]: {
            width: "28%",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 25,
    },
    SubmitButton: {
        width: '85%',
        height: theme.spacing(5),
        '&.MuiButtonBase-root': {
            marginTop: theme.spacing(4),
        }
    },
    Username: {
        width: '85%'
    },
    Password: {
        width: '85%',
    },
    Label: {
        paddingTop: theme.spacing(4),
        width: '85%',
        textAlign: 'left',
    }
}));

export default function Login() {
    const {authApi} = useContext<Api>(ApiContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {classes} = useStyles();
    const navigate = useNavigate();
    const {user, updateUser} = useJwtUser();

    if (user) {
        return <Navigate to={RoutePaths.herbs.path} replace/>;
    }

    const onSubmit = (event: any) => {
        event.preventDefault();
        authApi.postLogin({username, password})
            .then((res: AxiosResponse<JwtResponse>) => {
                updateUser(res.data)
                toast.success("Udało się zalogowac");
                navigate(RoutePaths.herbs.path);
            })
            .catch(() => {
            });
    };

    return (
        <form onSubmit={onSubmit}>
            <Box className={classes.Container}>
                <Paper elevation={6}
                       className={classes.LoginForm}>
                    <Typography variant={"h4"} color={"textPrimary"}>
                        Zaloguj
                    </Typography>
                    <Typography className={classes.Label}
                                variant={"h6"}
                                color={"textPrimary"}>
                        <Box fontWeight={"fontWeightBold"} m={1}>
                            Nazwa użytkownika
                        </Box>
                    </Typography>
                    <TextField className={classes.Username}
                               id={'username'}
                               variant={"outlined"}
                               name={'username'}
                               value={username}
                               label={'Nazwa użytkownika'}
                               onChange={(event) => setUsername(event.target.value)}
                    />
                    <Typography className={classes.Label}
                                variant={"h6"}
                                color={"textPrimary"}>
                        <Box fontWeight={"fontWeightBold"} m={1}>
                            Hasło
                        </Box>
                    </Typography>
                    <TextField className={classes.Password}
                               id={'password'}
                               variant={"outlined"}
                               name={'password'}
                               value={password}
                               label={'Hasło'}
                               onChange={(event) => setPassword(event.target.value)}
                               type={"password"}
                    />
                    <Button className={classes.SubmitButton}
                            variant={"contained"}
                            type={"submit"}
                            color={"primary"}>
                        Zaloguj
                    </Button>
                </Paper>
            </Box>
        </form>
    );
};
