import {HerbActions} from "../actions/HerbActions";
import {HerbData} from "../api/HerbData";

const emptyHerbData: HerbData = {
    id: null,
    name: '',
    nameLatin: '',
    workingDirection: '',
    directionality: '',
    dailyDoseFrom: '',
    dailyDoseTo: '',
    standardDose: '',
    usingTime: '',
    boilingTime: '',
    contraindications: '',
    pregnancy: '',
    physiological: '',
    pharmacological: '',
    toxicology: '',
    tolerance: '',
    notes: '',
    combinations: '',
    herbTastes: [],
    herbThermalCharacters: [],
    herbCircuits: [],
    herbGroups: [],
    herbActionCenters: [],
};

const herbReducer = (state: HerbData, action: HerbActions): HerbData => {
    switch (action.type) {
        case "change":
            return {...state, [action.fieldName]: action.fieldValue};
        case "setData":
            state = action.data;
            return state;
    }
};

export {emptyHerbData, herbReducer};