import {makeStyles} from "tss-react/mui";
import {VisitImages} from "../../types/VisitImages";
import {VisitActions} from "../../actions/VisitActions";
import VisitReadonlyGallery from "../../../images/components/VisitReadonlyGallery";
import VisitGalleryEditor from "../../../images/components/VisitGalleryEditor";
import {FileObject} from "mui-file-dropzone";

const useStyles = makeStyles()((theme) => ({
    root: {
        height: "100%",
    }
}));

type Props = {
    isReadOnly?: boolean;
    visitImages: VisitImages;
    dispatchChange?: (action: VisitActions) => void;
}

export default function VisitGallery(props: Props) {
    const {classes} = useStyles();

    const {
        isReadOnly,
        visitImages,
        dispatchChange
    } = props;

    const handleRemoveNewImage = (index: number): void => {
        dispatchChange({type: "removeNewImage", value: {index: index}})
    };

    const handleRemoveExisingImage = (imageId: number): void => {
        dispatchChange({type: "removeExistingImage", value: {imageId: imageId}})
    };

    const handleAddNewImages = (newImages: FileObject[]): void => {
        dispatchChange({type: "addNewImages", value: newImages})
    };

    return (
        <div className={classes.root}>
            {isReadOnly
                ? <VisitReadonlyGallery imageIds={visitImages.existingImageIds}/>
                : <VisitGalleryEditor data={visitImages}
                                      addNewImagesHandler={handleAddNewImages}
                                      removeExistingImageHandler={handleRemoveExisingImage}
                                      removeNewImageHandler={handleRemoveNewImage}
                />
            }
        </div>
    )
}
