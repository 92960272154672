import ApiBase from "../../common/api";
import {AxiosResponse} from "axios";
import * as URL from "../../common/constants/endpoints";
import PrescriptionFilterResponse from "./response/PrescriptionFilterResponse";
import {PostPrescriptionFilterRequest} from "./request/PostPrescriptionFilterRequest";
import {Prescription} from "./response/Prescription";
import {PutPrescriptionRequest} from "./request/PutPrescriptionRequest";

export default class PrescriptionApi extends ApiBase {

    postPrescription = (prescription: Prescription): Promise<AxiosResponse<Prescription>> => {
        return this.axiosInstance.post(URL.PRESCRIPTION_URL, prescription);
    };

    deletePrescription = (prescriptionId: number): Promise<AxiosResponse<void>> => {
        return this.axiosInstance.delete(
            `${URL.PRESCRIPTION_URL}${prescriptionId}`);
    }

    postFilterPrescriptions = (page: number, size: number, filterDto: PostPrescriptionFilterRequest):
        Promise<AxiosResponse<PrescriptionFilterResponse>> => {
        return this.axiosInstance.post(
            `${URL.PRESCRIPTION_URL}filter?page=${page}&size=${size}`,
            filterDto);
    };

    putPrescription = (request: PutPrescriptionRequest): Promise<AxiosResponse<void>> => {
        return this.axiosInstance.put(URL.PRESCRIPTION_URL, {
            ...request,
        });
    }
}
