import React, {useState} from "react";
import {Prescription} from "../api/response/Prescription";
import HerbsBrowserDialog from "../../herbs/components/HerbsBrowserDialog";
import PrescriptionCard from "./PrescriptionCard";
import {Box} from "@mui/material";

type Props = {
    className?: string,
    listData: Prescription[];
    prescriptionChaneHandler?: (prescription: Prescription) => void;
    prescriptionRemoveHandler: (prescriptionId: number) => void;
    prescriptionAddHandler: (prescription: Prescription) => void;
}

export default function PrescriptionListCreator(props: Props) {
    const [isHerbDialogOpen, setIsHerbDialogOpen] = useState(false);

    const {
        className,
        listData,
        prescriptionAddHandler,
        prescriptionChaneHandler,
        prescriptionRemoveHandler
    } = props;

    const handleOpenDialog = () => {
        setIsHerbDialogOpen(true);
    };

    const handleCloseHerbDialog = () => {
        setIsHerbDialogOpen(false);
    };

    return (
        <>
            <HerbsBrowserDialog isOpen={isHerbDialogOpen} handleClose={handleCloseHerbDialog}/>
            <Box className={className}>
                <PrescriptionCard
                    key={"empty-card"}
                    openHerbDialogHandler={handleOpenDialog}
                    cardDataChangeHandler={prescriptionChaneHandler}
                    cardDeleteHandler={prescriptionRemoveHandler}
                    cardSaveHandler={prescriptionAddHandler}
                />
                {listData.map(
                    (prescription, index) => {
                        return (
                            <PrescriptionCard key={index}
                                              openHerbDialogHandler={handleOpenDialog}
                                              prescriptionData={prescription}
                                              cardDataChangeHandler={prescriptionChaneHandler}
                                              cardDeleteHandler={prescriptionRemoveHandler}
                                              cardSaveHandler={prescriptionAddHandler}
                            />
                        );
                    })}
            </Box>
        </>
    )
}
