import React from 'react';
import Navigation from "../navigation/Navigation";
import {Navigate, Outlet} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {RequireAuth} from "./RequireAuth";
import useJwtUser from "../../hooks/useJwtUser";
import {RoutePaths} from "../../constants/routePaths";


export default function ProtectedContent() {
    const {user} = useJwtUser();

    if (!user) {
        return <Navigate to={RoutePaths.login.route} replace/>
    }

    return (
        <>
            <RequireAuth>
                <Outlet/>
            </RequireAuth>
        </>
    );
};
