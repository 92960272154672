import useJwtUser from "../../hooks/useJwtUser";
import {Navigate, Outlet} from "react-router-dom";
import {RoutePaths} from "../../constants/routePaths";
import React from "react";

export default function UnprotectedContent() {
    const {user} = useJwtUser();

    if (user) {
        return <Navigate to={RoutePaths.herbs.path} replace/>
    }

    return (
        <Outlet/>
    )
}