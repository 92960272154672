import MuiDialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 0,
        padding: theme.spacing(2),
    },
}));

export type AppDialogTitleProps = {
    children: React.ReactNode;
    onClose: () => void;
}

const AppDialogTitle = ((props: AppDialogTitleProps) => {
    const {classes} = useStyles();
    const {children, onClose, ...other} = props;
    return (
        <MuiDialogTitle className={classes.dialogTitle} {...other}>
            <div>
                <Typography variant="h6">{children}</Typography>
            </div>
            {onClose ? (
                <IconButton onClick={onClose} size="large">
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
})

export default AppDialogTitle;
