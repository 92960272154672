import {InfiniteListItemProps} from "../../../common/components/AppInfiniteLoadingList";
import {GetPatientDocumentsResponseItem} from "../../api/response/GetPatientDocumentsResponseItem";
import {Box, Button, Divider, Paper, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useDocumentListItemViewStyle} from "./styles";
import {Api, ApiContext} from "../../../common/api";
import {PutPatientDocumentRequest} from "../../api/request/PutPatientDocumentRequest";
import {toast} from "react-toastify";
import UpdateDocumentDialog from "./dialogs/UpdateDocumentDialog";

export default function DocumentListItemView(
    {
        deleteHandler,
        data,
        lastItemRef
    }: InfiniteListItemProps<GetPatientDocumentsResponseItem>) {

    const [isOpen, setIsOpen] = useState(false);
    const [currentData, setCurrentData] = useState({} as GetPatientDocumentsResponseItem)
    const {classes} = useDocumentListItemViewStyle();
    const {patientApi} = useContext<Api>(ApiContext);

    useEffect(() => {
        if (data) {
            setCurrentData(data);
        }
    }, [data.id]);

    const update = (newData: PutPatientDocumentRequest) => {
        if (newData) {
            patientApi.putPatientDocument(data.id, newData)
                .then(() => {
                    toast.success("Uaktualniono dokument pacjenta")
                    setCurrentData((prevState) => ({...prevState, text: newData.text, title: newData.title}))
                    setIsOpen(false)
                }).catch(() => {})
        }
    }

    const handleConfirmDialog = ((newData: PutPatientDocumentRequest) => {
        update(newData);
    });

    const handleCancelDialog = () => {
        setIsOpen(false);
    }

    const handleClickUpdate = () => {
        setIsOpen(true)
    }

    return (
        <Paper {...{ref: lastItemRef} as any} variant={"outlined"} className={classes.root}>
            <UpdateDocumentDialog isOpen={isOpen}
                                  data={data}
                                  handleConfirm={handleConfirmDialog}
                                  handleCancel={handleCancelDialog}/>
            <Box className={classes.headerBox}>
                <Typography variant="h6">{currentData.title}</Typography>
            </Box>
            <Divider/>
            <Typography className={classes.documentText}>{currentData.text}</Typography>
            <Box className={classes.buttonBox}>
                <Button className={classes.button}
                        onClick={() => deleteHandler(data.id)}
                        color={"primary"}
                        size={"small"}>
                    Usuń
                </Button>
                <Button className={classes.button}
                        color={"primary"}
                        onClick={() => handleClickUpdate()}
                        size={"small"}>
                    Edytuj
                </Button>

            </Box>
        </Paper>
    )
}
