import ApiBase from "../../common/api";
import {AxiosResponse} from "axios";
import * as URL from "../../common/constants/endpoints";
import {GetVisitImageResponse} from "./response/GetVisitImageResponse";
import {PutVisitImagesRequest} from "./request/PutVisitImagesRequest";

export default class ImagesApi extends ApiBase {

    postThumbnailImage = (herbId: number, file: File): Promise<AxiosResponse> => {
        const formData = new FormData();
        formData.append('file', file);
        return this.axiosInstance.post(
            `${URL.IMAGE_URL}/herbs/${herbId}/thumbnail`,
            formData,
            this.uploadImageRequestConfig
        );
    }

    postVisitImages = (visitId: number, files: File[]): Promise<AxiosResponse<void>> => {
        const formData = new FormData();

        if (files) {
            files.forEach(file => formData.append("files", file));
        }

        return this.axiosInstance.post(
            `${URL.IMAGE_URL}/visit/${visitId}`,
            formData,
            this.uploadImageRequestConfig
        );
    }

    putVisitImages = (visitId: number, request: PutVisitImagesRequest): Promise<AxiosResponse<void>> => {
        const formData = new FormData();

        if (request.existingImageIds) {
            formData.append(
                "existingImageIds",
                new Blob([JSON.stringify(request.existingImageIds)], {type: "application/json"})
            );
        }

        if (request.newImageFiles) {
            request.newImageFiles.forEach(file => formData.append("newImageFiles", file));
        }

        return this.axiosInstance.put(
            `${URL.IMAGE_URL}/visit/${visitId}`,
            formData,
            this.uploadImageRequestConfig
        );
    }

    getImageFileById = (imageId: number): Promise<File> => {
        return this.getImageFile(`${URL.IMAGE_URL}/${imageId}/file`);
    }

    getThumbnailImageFile = (herbId: number): Promise<File> => {
        return this.getImageFile(`${URL.IMAGE_URL}/thumbnail/${herbId}`)
    }

    getVisitImages = (visitId: number): Promise<AxiosResponse<GetVisitImageResponse[]>> => {
        return this.axiosInstance.get(
            `${URL.IMAGE_URL}/visits/${visitId}`
        );
    }

    private getImageFile = (url: string): Promise<File> => {
        return new Promise<File>((resolve, reject) => {
            this.axiosInstance.get(
                url,
                this.getImageFileConfig
            ).then((resp: AxiosResponse<ArrayBuffer>) => {
                    const contentType = resp.headers["content-type"];
                    const filename = resp.headers["content-disposition"].split('filename=')[1].split(';')[0];
                    return resolve(new File([resp.data], filename, {type: contentType}));
                }
            ).catch((error) => reject(error));
        })
    }
}
