import {makeStyles} from "tss-react/mui";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {Prescription} from "../api/response/Prescription";
import React from "react";
import {parseDate} from "../../common/utils/utils";
import Divider from "@mui/material/Divider";
import {properties} from "../../common/constants/properties";

const useStyles = makeStyles()((theme) => ({
    container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    content: {
        padding: theme.spacing(1),
        borderRadius: 20
    },
    cardContainer: {
        textAlign: 'left',
        marginBottom: theme.spacing(2),
    },
    date: {
        fontStyle: 'italic',
    },
    processing: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    }
}));

type Props = {
    data: Prescription;
}

export default function MedicineReadOnlyListItem({data}: Props) {
    const {classes} = useStyles();

    return (
        <Box className={classes.container}>
            <Typography color="textSecondary"
                        className={classes.date}>
                Wystawiona dnia: {parseDate(data.created)}
            </Typography>
            <Paper className={classes.content}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align={"left"}>{properties.medicineListIndex.label}</TableCell>
                                <TableCell align={"left"}>{properties.medicineListHerbName.label}</TableCell>
                                <TableCell align={"left"}>{properties.medicineListBoilingTime.label}</TableCell>
                                <TableCell align={"left"}>{properties.medicineListDose.label}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.medicineList.map(
                                (medicine, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align={"left"}>{index + 1}</TableCell>
                                            <TableCell align={"left"}>{medicine.name}</TableCell>
                                            <TableCell align={"left"}>{medicine.boilingTime}</TableCell>
                                            <TableCell align={"left"}>{medicine.dose}</TableCell>
                                        </TableRow>
                                    )
                                }
                            )}
                        </TableBody>
                    </Table>
                    <Divider/>
                    <Typography className={classes.processing}>{data.herbProcessing}</Typography>
                </TableContainer>
            </Paper>
        </Box>
    );
}
