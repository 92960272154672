import {Box, Card} from "@mui/material";
import PatientCardBasicDataView from "./PatientCardBasicDataView";
import PatientCardVisitsList from "../PatientCardVisitsList/PatientCardVisitsList";
import PatientCardDocumentsList from "../PatientCardDocumentsList/PatientCardDocumentsList";
import {useContext, useEffect, useState} from "react";
import {Api, ApiContext} from "../../../common/api";
import {AxiosResponse} from "axios";
import {GetPatientCardBasicDataResponse} from "../../api/response/GetPatientCardBasicDataResponse";
import {usePatientCardViewStyle} from "./styles";
import {useParams} from "react-router-dom";

export default function PatientCardView() {
    const {classes} = usePatientCardViewStyle();
    const [data, setData] = useState({} as GetPatientCardBasicDataResponse)
    const {patientApi} = useContext<Api>(ApiContext);
    const {patientId} = useParams();

    useEffect(() => {
        patientApi.getPatientBasicData(Number(patientId))
            .then((resp: AxiosResponse<GetPatientCardBasicDataResponse>) => {
                setData(resp.data);
            }).catch(() => {
        })
    }, [patientId])

    return (
        <Box>
            <PatientCardBasicDataView data={data}/>
            <Card className={classes.body}>
                <PatientCardVisitsList patientId={Number(patientId)}/>
                <PatientCardDocumentsList patientId={Number(patientId)}/>
            </Card>
        </Box>
    )
}
