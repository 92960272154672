import React, {useCallback, useState} from 'react';
import {PostSearchHerbsRequest} from "../api/request/PostSearchHerbsRequest";
import HerbsBrowserFilter from './HerbsBrowserFilter';
import {MemoizedList} from "./HerbsList";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    herbContainer: {
        width: '100%',
    },
}));

const defaultFilterDto: PostSearchHerbsRequest = {
    directionality: "",
    herbActionCenters: "",
    herbGroups: "",
    name: "",
    nameLatin: "",
    pregnancy: ""
};

export default function HerbsBrowser() {
    const {classes} = useStyles();
    const [filter, setFilter] = useState(defaultFilterDto);

    const onFilterChange = useCallback((newFilter: PostSearchHerbsRequest): void => {
        setFilter((prevState => {
            return {...prevState, ...newFilter}
        }));
    }, []);

    return (
        <div>
            <div className={classes.herbContainer}>
                <HerbsBrowserFilter onFilterChanged={onFilterChange}/>
                <MemoizedList filter={filter} isDialog={false}/>
            </div>
        </div>
    );
}
