import React from 'react'
import {useNavigate} from "react-router-dom";
import {RoutePaths} from '../../constants/routePaths'
import SearchIcon from '@mui/icons-material/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from "@mui/material/Paper";
import {Button, Menu, MenuItem} from "@mui/material";
import {getUsernameFromStorage} from "../../utils/utils";
import Typography from "@mui/material/Typography";
import {makeStyles} from "tss-react/mui";
import AppImage from "../../../images/components/AppImage";
import useJwtUser from "../../hooks/useJwtUser";

const useStyles = makeStyles()((theme) => ({
    NavigationContainer: {
        width: '100%',
        marginBottom: '24px',
    },
    NavigationButtons: {
        display: 'flex',
        alignItems: 'center',
    },
    NavigationBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    UsernameContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: theme.spacing(2)
    },
    Username: {
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    SearchNavButton: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    PrescriptionNavButton: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    RecordsNavButton: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    Logo: {
        width: 40,
        height: 40,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
}));

export default function Navigation() {
    const {classes} = useStyles();
    const navigate = useNavigate();
    // const store = useContext(LocalStorageContext);
    const {clearUser} = useJwtUser();
    const [menuAnchor, setMenuAnchor] = React.useState(null);

    const handleUserMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleChange = (newValue: string) => {
        switch (newValue) {
            case RoutePaths.herbs.route:
                navigate(RoutePaths.herbs.path);
                break;
            case RoutePaths.login.route:
                navigate(RoutePaths.login.path);
                break;
            case RoutePaths.createHerb.route:
                navigate(RoutePaths.createHerb.path);
                break;
            case RoutePaths.prescriptionsPractice.route:
                navigate(RoutePaths.prescriptionsPractice.path);
                break;
            case RoutePaths.patients.route:
                navigate(RoutePaths.patients.path);
                break;
            default:
                navigate(RoutePaths.herbs.path)
        }
    };

    return (
        <Paper className={classes.NavigationContainer} elevation={3}>
            <div className={classes.NavigationBar}>
                <div className={classes.NavigationButtons}>
                    <AppImage className={classes.Logo} src={'leaf-graphic.png'} alt={"Zioła"}/>
                    <Button
                        className={classes.SearchNavButton}
                        variant="contained"
                        startIcon={<SearchIcon/>}
                        onClick={() => handleChange(RoutePaths.herbs.route)}>
                        Wyszukiwarka
                    </Button>
                    <Button
                        className={classes.PrescriptionNavButton}
                        variant="contained"
                        startIcon={<AssignmentIcon/>}
                        onClick={() => handleChange(RoutePaths.prescriptionsPractice.route)}>
                        Recepta
                    </Button>
                    <Button
                        className={classes.RecordsNavButton}
                        variant="contained"
                        startIcon={<AssignmentIndIcon/>}
                        onClick={() => handleChange(RoutePaths.patients.route)}>
                        Kartoteka Pacjentów
                    </Button>
                </div>
                {getUsernameFromStorage() &&
                    <div className={classes.UsernameContainer}>
                        <Button
                            variant='text'
                            onClick={handleUserMenuOpen}>
                            <div className={classes.Username}>
                                <Typography variant={'h6'}
                                            color={'textSecondary'}>
                                    {getUsernameFromStorage()}
                                </Typography>
                                <ArrowDropDownIcon/>
                            </div>
                        </Button>
                        <Menu
                            id="user-menu"
                            anchorEl={menuAnchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            open={Boolean(menuAnchor)}
                            onClose={handleUserMenuClose}
                        >
                            <MenuItem onClick={() => {
                                clearUser();
                                handleChange(RoutePaths.login.route);
                            }}>
                                Wyloguj
                            </MenuItem>
                        </Menu>
                    </div>}
            </div>
        </Paper>
    )
};
