import {makeStyles} from "tss-react/mui";
import React, {LegacyRef, useCallback, useEffect, useRef, useState} from "react";
import {Box, Container} from "@mui/material";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {WithIdType} from "../types/WithIdType";
import PageResponse from "../api/PageResponse";
import useConfirm from "../hooks/useConfirm";

const useStyles = makeStyles()((theme) => ({
    listContainer: {
        height: "100%",
    },
    list: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1)
    }
}));

export type InfiniteListItemProps<T> = {
    data: T,
    deleteHandler?: (itemId: number) => void,
    openItemHandler?: (itemId: number) => void,
    lastItemRef?: LegacyRef<HTMLDivElement>
}

type Props<T, R> = {
    newItem?: T;
    requestParams: R;
    ListItemComponent: ({deleteHandler, openItemHandler, data, lastItemRef}: InfiniteListItemProps<T>) => JSX.Element;
    apiFetchPaginatedItems: (page: number, size: number, requestParams: R) => Promise<AxiosResponse<PageResponse<T>>>;
    apiDeleteItem?: (itemId: number) => Promise<AxiosResponse<void>>;
    openItemHandler?: (itemId: number) => void;
}

export default function AppInfiniteLoadingList<T extends WithIdType, R>(props: Props<T, R>) {
    const {classes} = useStyles();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [data, setData] = useState([] as T[])
    const [hasMore, setHasMore] = useState(false)
    const {withConfirm} = useConfirm();

    const {
        ListItemComponent,
        requestParams,
        apiDeleteItem,
        openItemHandler,
        apiFetchPaginatedItems,
    } = props;

    useEffect(() => {
        if (props.newItem && props.newItem.id) {
            setData((prevData) => {
                return [props.newItem, ...prevData];
            });
        }
    }, [props.newItem])

    useEffect(() => {
        setLoading(true);
        setError(false);
        apiFetchPaginatedItems(page, 8, requestParams)
            .then((resp: AxiosResponse<PageResponse<T>>) => {
                setData((prevData) => {
                    return [...prevData, ...resp.data.content];
                });
                setHasMore((resp.data.number + 1) * resp.data.size < resp.data.totalElements);
                setLoading(false);
            }).catch(() => {
            setError(true);
        });
    }, [page, requestParams])

    const lastItemObserver = useRef<IntersectionObserver>();
    const lastItemRef = useCallback((node: HTMLElement) => {
        if (loading) return;
        if (lastItemObserver.current) lastItemObserver.current.disconnect();
        lastItemObserver.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        })
        if (node) lastItemObserver.current.observe(node);
    }, [loading, hasMore])

    const handleOpenItem = useCallback((itemId: number) => {
        openItemHandler(itemId);
    }, []);

    const handleDelete = useCallback(async (itemId: number) => {
        await withConfirm(() => deleteItem(itemId), "Czy na pewno chcesz usunąć?")
    }, [])

    const deleteItem = (itemId: number) => {
        apiDeleteItem(itemId)
            .then(() => {
                toast.success("Usunięto wizytę");
                deleteListItem(itemId);
            }).catch(() => {
        })
    }

    const deleteListItem = (itemId: number): void => {
        setData(prevState => prevState.filter(item => item.id !== itemId));
    }

    return (
        <>
            <Container className={classes.listContainer}>
                <Box className={classes.list}>
                    {data.map((item, index) => {
                        if (data.length === index + 1) {
                            return <ListItemComponent key={index}
                                                      deleteHandler={handleDelete}
                                                      openItemHandler={handleOpenItem}
                                                      data={item}
                                                      lastItemRef={lastItemRef}
                            />
                        } else {
                            return <ListItemComponent key={index}
                                                      deleteHandler={handleDelete}
                                                      openItemHandler={handleOpenItem}
                                                      data={item}
                            />
                        }
                    })}
                </Box>
                <div>{loading && 'Ładuję...'}</div>
                <div>{error && 'Błąd'}</div>
            </Container>
        </>
    );
}
