import React from "react";
import {Divider, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Add} from "@mui/icons-material";
import {Medicine} from "../api/response/Medicine";
import {makeStyles} from "tss-react/mui";
import {PrescriptionCardMode} from "../types/PrescriptionCardMode";
import MedicineListItem from "./MedicineListItem";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles()((theme) => ({
    listContainer: {
        width: '100%',
        padding: 0,
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: theme.spacing(1),
        marginBottom: 0
    }
}))

type Props = {
    mode: PrescriptionCardMode;
    medicineList: Medicine[];
    medicineChangeHandler: (index: number, propName: string, value: string) => void;
    deleteMedicineHandler: (index: number) => void;
}

export default function MedicineList(props: Props) {
    const {
        mode,
        medicineList,
        medicineChangeHandler,
        deleteMedicineHandler,
    } = props;

    const {classes} = useStyles();

    const isReadonly = (): boolean => {
        return mode === PrescriptionCardMode.LIST_ITEM;
    }

    return (
        <>
            <div className={classes.header}>
                <Typography variant={"h6"}>
                    Przepisane zioła
                </Typography>
            </div>
            {medicineList.length !== 0 && <Divider className={classes.divider}/>}
            <List className={classes.listContainer}>
                {medicineList.map(
                    (medicineData, index) => {
                        return (
                            <div key={index}>
                                <MedicineListItem
                                    index={index}
                                    itemData={medicineData}
                                    medicineChangeHandler={medicineChangeHandler}
                                    deleteMedicineHandler={deleteMedicineHandler}
                                    isReadOnly={isReadonly()}
                                />
                                {medicineList.length - 1 !== index &&
                                    <Divider className={classes.divider}/>
                                }
                            </div>
                        );
                    })
                }
            </List>
        </>
    );
}
