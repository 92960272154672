import ApiBase from "../../common/api";
import {AxiosResponse} from "axios";
import {JwtResponse} from "./response/JwtResponse";
import * as URL from "../../common/constants/endpoints";
import {PostLoginRequest} from "./request/PostLoginRequest";

export default class AuthApi extends ApiBase {

    postLogin = (loginRequest: PostLoginRequest): Promise<AxiosResponse<JwtResponse>> => {
        return this.axiosInstance.post(`${URL.AUTH_URL}signin`, loginRequest);
    }

    verifyJwt = (): Promise<AxiosResponse> => {
        return this.axiosInstance.get(`${URL.AUTH_URL}verify`);
    }
}
