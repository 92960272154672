import {PostFindPatientsRequest} from "../../api/request/PostFindPatientsRequest";
import {Button, Card, Grid} from "@mui/material";
import React, {useState} from "react";
import AppTextField, {OnChangeTexFieldPayload} from "../../../common/components/AppTextField";
import {usePatientListSearchBoxStyle} from "./styles";
import {properties} from "../../../common/constants/properties";

type Props = {
    params: PostFindPatientsRequest;
    onSearchParamsChange: (params: PostFindPatientsRequest) => void;
};

const defaultSearchParams: PostFindPatientsRequest = {
    name: '',
    surname: '',
    address: '',
};

export default function PatientListSearchBox({params, onSearchParamsChange}: Props) {
    const [searchParams, setSearchParams] = useState(defaultSearchParams);
    const {classes} = usePatientListSearchBoxStyle();

    const onChange = (payload: OnChangeTexFieldPayload): void => {
        setSearchParams((prevState) => {
            return {...prevState, [payload.fieldName]: payload.fieldValue}
        });
    }

    return (
        <Card className={classes.main}>
            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={4} className={classes.gridItem}>
                    <AppTextField value={searchParams.name}
                                  property={properties.patientSearchBoxNameField}
                                  className={classes.textField}
                                  onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.gridItem}>
                    <AppTextField property={properties.patientSearchBoxSurnameField}
                                  className={classes.textField}
                                  value={searchParams.surname}
                                  onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.gridItem}>
                    <AppTextField className={classes.textField}
                                  value={searchParams.address}
                                  property={properties.patientSearchBoxAddressField}
                                  onChange={onChange}
                    />
                </Grid>
            </Grid>
            <Button className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => onSearchParamsChange(searchParams)}>
                Szukaj
            </Button>
        </Card>
    )


};
