import React, {useContext} from "react";
import {Api, ApiContext} from "../../common/api";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import PrescriptionFilterResponse from "../api/response/PrescriptionFilterResponse";
import {Prescription} from "../api/response/Prescription";
import {makeStyles} from "tss-react/mui";
import PrescriptionListCreator from "./PrescriptionListCreator";
import {PostPrescriptionRequest} from "../api/request/PostPrescriptionRequest";

const useStyles = makeStyles()((theme) => ({
    listContainer: {
        width: '60%',
    }
}))

type Props = {
    listData: PrescriptionFilterResponse;
    listDataChangedHandler: (listData: Prescription[]) => void;
}

const mapPostPrescriptionRequest = (prescription: Prescription) => {
    return {
        herbProcessing: prescription.herbProcessing,
        nameSurname: prescription.nameSurname,
        medicineList: prescription.medicineList
    } as PostPrescriptionRequest
}

export default function PracticePrescriptionList({listData, listDataChangedHandler}: Props) {
    const {classes} = useStyles();
    const {prescriptionApi} = useContext<Api>(ApiContext)

    const handleRowChanged = (prescriptionData: Prescription) => {
        prescriptionApi.putPrescription({
            id: prescriptionData.id,
            medicineList: prescriptionData.medicineList,
            herbProcessing: prescriptionData.herbProcessing,
            nameSurname: prescriptionData.nameSurname
        })
            .then((resp: AxiosResponse<void>) => {
                toast.success("Zapisano receptę");
            }).catch(() => {});
    }

    const handleRemoveRow = (prescriptionId: number) => {
        prescriptionApi.deletePrescription(prescriptionId)
            .then(() => {
                toast.success("Usunięto receptę");

                const rowsData = listData.content.filter(rowData => rowData.id !== prescriptionId);

                listDataChangedHandler(rowsData);
            }).catch(() => {});
    }

    const handleCreatePrescription = (rowData: Prescription) => {
        const request = mapPostPrescriptionRequest(rowData);
        prescriptionApi.postPrescription(request)
            .then((resp: AxiosResponse<Prescription>) => {
                toast.success("Dodano nową receptę");
                let newListData: Prescription[] = [
                    {...resp.data},
                    ...listData.content];

                listDataChangedHandler(newListData);
            }).catch(() => {});
    }

    return (
        <>
            <PrescriptionListCreator className={classes.listContainer}
                                     listData={listData.content}
                                     prescriptionChaneHandler={handleRowChanged}
                                     prescriptionRemoveHandler={handleRemoveRow}
                                     prescriptionAddHandler={handleCreatePrescription}
            />
        </>
    );
};
