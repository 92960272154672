import {makeStyles} from "tss-react/mui";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import AppTextField, {OnChangeTexFieldPayload} from "../../../../common/components/AppTextField";
import {properties} from "../../../../common/constants/properties";
import React, {useEffect, useState} from "react";
import {PatientDocumentDto} from "../../../types/PatientDocumentDto";

const useStyles = makeStyles()((theme) => ({
    textArea: {
        width: '100%',
        margin: theme.spacing(1),
        "& textarea": {
            minHeight: 'calc(1.876em * 3)'
        },
    },
    textField: {
        width: '100%',
        margin: theme.spacing(1),
    }
}));

type Props = {
    isOpen: boolean;
    handleClose: (data?: PatientDocumentDto) => void;
    text?: string;
    title?: string;
}

export default function DocumentDialogView(props: Props) {
    const {classes} = useStyles();
    const [document, setDocument] = useState<PatientDocumentDto>({} as PatientDocumentDto);

    const {
        isOpen,
        handleClose,
        text,
        title
    } = props;

    useEffect(() => {
        if (text || title) {
            setDocument({text: text, title: title})
        } else {
            setDocument({});
        }
    }, [isOpen])

    const handleSave = () => {
        handleClose(document);
    }

    const handleChange = (value: OnChangeTexFieldPayload) => {
        setDocument(prevState => ({...prevState, [value.fieldName]: value.fieldValue}));
    }

    return (
        <Dialog open={isOpen}
                scroll="paper"
                maxWidth="md">
            <DialogTitle>
                <Typography>Dokument Pacjenta</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Box>
                    <AppTextField property={properties.patientDocumentTitle}
                                  value={document.title}
                                  onChange={handleChange}
                                  className={classes.textField}
                    />
                    <AppTextField property={properties.patientDocumentText}
                                  value={document.text}
                                  onChange={handleChange}
                                  className={classes.textArea}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}
                        color={"primary"}>
                    Anuluj
                </Button>
                <Button onClick={() => handleSave()}
                        color={"primary"}>
                    Zapisz
                </Button>
            </DialogActions>
        </Dialog>
    )
};
