import {VisitActions} from "../../actions/VisitActions";
import {Prescription} from "../../../prescription/api/response/Prescription";
import PatientPrescriptionReadOnlyList from "../../../prescription/components/PatientPrescriptionReadOnlyList";
import PrescriptionListCreator from "../../../prescription/components/PrescriptionListCreator";

type Props = {
    data?: Prescription[];
    isReadOnly?: boolean;
    dispatchChange?: (action: VisitActions) => void;
}

// TODO: Deleting of one deletes everything
export default function VisitPrescriptions({data, dispatchChange, isReadOnly}: Props) {

    const handleRemove = (itemId: number) => {
        dispatchChange({type: "removePrescriptionItem", value: {id: itemId}});
    }

    const handleAdd = (prescription: Prescription) => {
        dispatchChange({type: "addPrescriptionItem", value: prescription});
    }

    return (
        <>
            {isReadOnly
                ? <PatientPrescriptionReadOnlyList data={data}/>
                : <PrescriptionListCreator listData={data}
                                           prescriptionRemoveHandler={handleRemove}
                                           prescriptionAddHandler={handleAdd}
                />
            }
        </>
    );
}
