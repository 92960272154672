import {makeStyles} from "tss-react/mui";
import {Box, Grid, TextField} from "@mui/material";
import AppTextField, {OnChangeTexFieldPayload} from "../../../common/components/AppTextField";
import React, {useCallback} from "react";
import {VisitActions} from "../../actions/VisitActions";
import {BasicData} from "../../types/BasicData";
import {properties} from "../../../common/constants/properties";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import plLocale from "date-fns/locale/pl";
import {Dayjs} from "dayjs";

const useStyles = makeStyles()((theme) => ({
    textArea: {
        width: '100%',
        margin: theme.spacing(1),
        "& textarea": {
            minHeight: 'calc(1.876em * 2)'
        },
    },
    textField: {
        width: '100%',
        margin: theme.spacing(1),
    }
}))

type Props = {
    data: BasicData;
    isReadOnly?: boolean,
    dispatchChange?: (action: VisitActions) => void;
}

export default function VisitBasicDataForm({data, dispatchChange, isReadOnly}: Props) {
    const {classes} = useStyles();

    const handleChange = useCallback((value: OnChangeTexFieldPayload) => {
        dispatchChange({type: "changeBasicData", value: value})
    }, []);

    const handleVisitDateChange = useCallback((date: Dayjs | null) => {
        dispatchChange({type: "updateVisitDate", value: date})
    }, []);

    return (
        <Box>
            <Grid container spacing={2}>
                {!isReadOnly &&
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
                            <DatePicker
                                label={properties.visitDate.label}
                                inputFormat={"dd/MM/yyyy"}
                                onChange={handleVisitDateChange}
                                className={classes.textField}
                                value={data.date}
                                renderInput={(params) => (
                                    <TextField
                                        variant={"outlined"}
                                        {...params} />)}
                            />
                        </LocalizationProvider>
                    </Grid>}
                <Grid item xs={12}>
                    <AppTextField property={properties.visitSyndrome}
                                  value={data.syndrome}
                                  onChange={handleChange}
                                  className={classes.textArea}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AppTextField property={properties.visitDiagnosis}
                                  value={data.diagnosis}
                                  onChange={handleChange}
                                  className={classes.textArea}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AppTextField property={properties.visitAilments}
                                  value={data.ailments}
                                  onChange={handleChange}
                                  className={classes.textArea}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AppTextField property={properties.visitTests}
                                  value={data.tests}
                                  onChange={handleChange}
                                  className={classes.textArea}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AppTextField property={properties.pulseNote}
                                  value={data.note}
                                  onChange={handleChange}
                                  className={classes.textArea}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextField property={properties.rightCun}
                                  value={data.rightCun}
                                  onChange={handleChange}
                                  className={classes.textField}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextField property={properties.leftCun}
                                  value={data.leftCun}
                                  onChange={handleChange}
                                  className={classes.textField}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextField property={properties.rightGuan}
                                  value={data.rightGuan}
                                  onChange={handleChange}
                                  className={classes.textField}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextField property={properties.leftGuan}
                                  value={data.leftGuan}
                                  onChange={handleChange}
                                  className={classes.textField}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextField property={properties.rightChi}
                                  value={data.rightChi}
                                  onChange={handleChange}
                                  className={classes.textField}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextField property={properties.leftChi}
                                  value={data.leftChi}
                                  onChange={handleChange}
                                  className={classes.textField}
                                  isReadOnly={isReadOnly}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
