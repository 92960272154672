import React from "react";
import {PostPatientDocumentRequest} from "../../../api/request/PostPatientDocumentRequest";
import {PatientDocumentDto} from "../../../types/PatientDocumentDto";
import DocumentDialogView from "./DocumentDialogView";

type Props = {
    isOpen: boolean;
    handleConfirm: (data?: PostPatientDocumentRequest) => void;
    handleCancel: () => void;
    patientId: number;
}

export default function CreateDocumentDialog(props: Props) {
    const {
        isOpen,
        handleConfirm,
        handleCancel,
        patientId
    } = props;

    const handleSave = (data: PatientDocumentDto) => {
        if (data) {
            handleConfirm({patientId: patientId, title: data.title, text: data.text} as PostPatientDocumentRequest);
        } else {
            handleCancel();
        }
    }

    return (
        <DocumentDialogView isOpen={isOpen}
                            handleClose={handleSave}/>
    )
}
