import React from 'react';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';
import {createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material/styles"
import ProtectedContent from "./ProtectedContent";
import {LocalStorageProvider} from "../../store";
import createCache from '@emotion/cache'
import {CacheProvider} from "@emotion/react";
import {CssBaseline} from "@mui/material";
import ConfirmProvider from "../../store/ConfirmContext";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import {RoutePaths} from "../../constants/routePaths";
import Login from "../../../auth/components/Login";
import HerbsBrowser from "../../../herbs/components/HerbsBrowser";
import PatientBrowser from "../../../patient/components/PatientBrowser/PatientBrowser";
import CreatePatient from "../../../patient/components/CreatePatient";
import EditPatient from "../../../patient/components/EditPatient";
import PatientCardView from "../../../patient/components/PatientCardView/PatientCardView";
import {CreateVisit} from "../../../patient/components/visit/CreateVisit";
import EditVisit from "../../../patient/components/visit/EditVisit";
import PrescriptionListPracticeView from "../../../prescription/components/PrescriptionListPracticeView";
import EditHerb from "../../../herbs/components/EditHerb";
import CreateHerb from "../../../herbs/components/CreateHerb";
import {ToastContainer} from "react-toastify";
import Layout from "./Layout";
import UnprotectedContent from "./UnprotectedContent";

const theme = createTheme(({
    typography: {
        fontFamily: [
            'Arial', '"Helvetica Neue"', 'Helvetica', 'sans-serif'
        ].join(','),
    },
    palette: {
        text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)',
        },
        background: {
            default: '#303030',
            paper: '#424242',
        },
        secondary: {
            light: '#4c8c4a',
            main: '#1b5e20',
            dark: '#003300',
            contrastText: '#ffffff',
        },
        primary: {
            light: '#98ee99',
            main: '#66bb6a',
            dark: '#338a3e',
            contrastText: '#000000',
        },
        mode: "dark"
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                span {
                    white-space: pre-line;
                }
           `,
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    "&:last-child": {
                        paddingBottom: 0,
                    },
                },
            },
        },
    },
}));

const muiCache = createCache({
    'key': 'mui',
    'prepend': true
})

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Layout/>}>
            <Route element={<UnprotectedContent/>}>
                <Route index element={<Login/>}/>
                <Route path={RoutePaths.login.path} element={<Login/>}/>
            </Route>
            <Route element={<ProtectedContent/>}>
                <Route index path={RoutePaths.createHerb.path} element={<CreateHerb/>}/>
                <Route path={RoutePaths.editHerb.path} element={<EditHerb/>}/>
                <Route path={RoutePaths.herbs.path} element={<HerbsBrowser/>}/>
                <Route path={RoutePaths.createPatient.path} element={<CreatePatient/>}/>
                <Route path={RoutePaths.prescriptionsPractice.path} element={<PrescriptionListPracticeView/>}/>
                <Route path={RoutePaths.editPatient.path} element={<EditPatient/>}/>
                <Route path={RoutePaths.patientCard.path} element={<PatientCardView/>}/>
                <Route path={RoutePaths.createVisit.path} element={<CreateVisit/>}/>
                <Route path={RoutePaths.editVisit.path} element={<EditVisit/>}/>
                <Route path={RoutePaths.patients.path} element={<PatientBrowser/>}/>
            </Route>
        </Route>
    )
);

export default function App() {
    return (
        <CacheProvider value={muiCache}>
            <ThemeProvider theme={responsiveFontSizes(theme)}>
                <CssBaseline/>
                <LocalStorageProvider>
                        <ConfirmProvider>
                            <ToastContainer
                                position="top-right"
                                autoClose={1000}
                                hideProgressBar
                                newestOnTop
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                pauseOnHover
                            />
                            <RouterProvider router={router}/>
                            <ConfirmDialog/>
                        </ConfirmProvider>
                </LocalStorageProvider>
            </ThemeProvider>
        </CacheProvider>
    )
};
