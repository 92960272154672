import React, {useCallback, useContext, useEffect, useReducer} from 'react'
import {Api, ApiContext} from "../../common/api";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../common/constants/routePaths";
import {PostSearchHerbsRequest} from "../api/request/PostSearchHerbsRequest";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import MaterialTable, {Action} from "@material-table/core";
import HerbDialog from "./HerbDialog";
import {makeStyles} from "tss-react/mui";
import {HerbListActions} from "../actions/HerbListActions";
import Card from '@mui/material/Card';
import {PostSearchHerbsResponseItem} from "../api/response/PostSearchHerbsResponseItem";
import PostSearchHerbsResponse from "../api/response/PostSearchHerbsResponse";
import useConfirm from "../../common/hooks/useConfirm";

const useStyles = makeStyles()((theme) => ({
    List: {
        borderRadius: 25,
        width: '100%',
    }
}));

type Props = {
    filter: PostSearchHerbsRequest;
    isDialog: boolean;
}

export type Data = {
    rows: PostSearchHerbsResponseItem[];
    totalCount: number;
}

export type DetailsDialogData = {
    isOpen: boolean;
    herbId?: number;
}

type State = {
    listData: Data;
    detailsDialogData: DetailsDialogData;
    currentPage: number;
    currentPageSize: number;
}

const defaultState: State = {
    currentPage: 0,
    currentPageSize: 8,
    detailsDialogData: {isOpen: false} as DetailsDialogData,
    listData: {} as Data
}

const listReducer = (state: State, action: HerbListActions): State => {
    switch (action.type) {
        case 'setDetailsDialogData':
            return {...state, detailsDialogData: action.value};
        case 'setListData':
            return {...state, listData: action.value};
        case 'setCurrentPage':
            return {...state, currentPage: action.value};
        case 'setCurrentPageSize':
            return {...state, currentPageSize: action.value};
        case 'deleteRow':
            let filteredData = state.listData.rows.filter((item: PostSearchHerbsResponseItem) => {
                return item.herbId !== action.value;
            });

            return {
                ...state,
                listData: {rows: filteredData, totalCount: state.listData.totalCount}
            };
    }
}

export default function HerbsList({filter, isDialog}: Props) {
    const {classes} = useStyles();
    const {withConfirm} = useConfirm();
    const navigate = useNavigate();
    const {herbsApi} = useContext<Api>(ApiContext);

    const [state, dispatchListState] = useReducer(listReducer, defaultState);

    useEffect(() => {
        herbsApi.postFilterHerb(state.currentPage, state.currentPageSize, filter)
            .then((res: AxiosResponse<PostSearchHerbsResponse>) => {
                dispatchListState({
                    type: 'setListData',
                    value: {
                        rows: res.data.content,
                        totalCount: res.data.totalElements
                    }
                });
            }).catch(() => {
        });
    }, [herbsApi, filter, state.currentPage, state.currentPageSize]);

    const handleDelete = useCallback(async (rowData?: PostSearchHerbsResponseItem) => {
        await withConfirm(
            () => deleteHerb(rowData.herbId),
            "Czy na pewno chcesz usunąć?");
    }, [])

    const deleteHerb = (herbId: number) => {
        herbsApi.deleteHerb(herbId)
            .then(res => {
                toast.success("Poprawnie usunięto zioło");
                dispatchListState({type: "deleteRow", value: herbId});
            }).catch(() => {
        });
    }

    function handleCloseDetailsDialog(): void {
        dispatchListState({type: "setDetailsDialogData", value: {isOpen: false}});
    }

    const getActions = (): (Action<object> | ((rowData: object) => Action<object>))[] => {
        if (isDialog) {
            return [{
                icon: 'info_outline',
                tooltip: 'Szczegóły zioła',
                onClick: (event, row: any) => {
                    dispatchListState({
                        type: "setDetailsDialogData",
                        value: {herbId: row.herbId, isOpen: true}
                    })
                },
            }]
        } else {
            return [
                {
                    icon: 'edit',
                    tooltip: 'Edytuj zioło',
                    onClick: (event, row: object) => {
                        let herbId = (row as PostSearchHerbsResponseItem).herbId;
                        navigate(RoutePaths.editHerb.getPath([herbId]))
                    }
                },
                {
                    icon: 'delete',
                    tooltip: 'Usun zioło',
                    onClick: (event, row: object) => handleDelete(row as PostSearchHerbsResponseItem),
                },
                {
                    icon: 'add',
                    tooltip: 'Dodaj zioło',
                    isFreeAction: true,
                    onClick: () => navigate(RoutePaths.createHerb.path),
                },
                {
                    icon: 'info_outline',
                    tooltip: 'Szczegóły zioła',
                    onClick: (event, row: object) => {
                        let herbId = (row as PostSearchHerbsResponseItem).herbId;
                        dispatchListState({
                            type: "setDetailsDialogData",
                            value: {herbId: herbId, isOpen: true}
                        })
                    },
                },
            ]
        }
    };

    return (
        <>
            <HerbDialog herbId={state.detailsDialogData.herbId}
                        isOpen={state.detailsDialogData.isOpen}
                        handleClose={handleCloseDetailsDialog}/>
            <Card className={classes.List}>
                <MaterialTable
                    page={state.currentPage}
                    totalCount={state.listData.totalCount}
                    onPageChange={(page => dispatchListState({type: "setCurrentPage", value: page}))}
                    onRowsPerPageChange={(pageSize => dispatchListState({
                        type: "setCurrentPageSize",
                        value: pageSize
                    }))}
                    title='Lista ziół'
                    columns={[
                        {title: 'Nazwa', field: 'herbName'},
                        {title: 'Nazwa łacińska', field: 'herbNameLatin'},
                        {title: 'Ośrodek działania', field: 'herbActionCenter'},
                        {title: 'kierunkowość', field: 'herbDirectionality'},
                        {title: 'Przynależność do obiegu', field: 'herbCircuit'},
                    ]}
                    localization={{
                        body: {},
                        header: {
                            actions: 'Akcje'
                        },
                        pagination: {
                            previousTooltip: 'Poprzednia Strona',
                            nextTooltip: 'Następna Strona',
                            lastTooltip: 'Ostatnia Strona',
                            firstTooltip: 'Pierwsza Strona',
                            labelDisplayedRows: '{from} - {to} z {count}',
                            labelRowsPerPage: "Wierszy na stronę",
                        },
                        toolbar: {
                            searchTooltip: 'Szukaj',
                            searchPlaceholder: 'Szukaj',
                        },
                    }}
                    options={{
                        pageSize: 8,
                        pageSizeOptions: [8, 16, 24],
                        actionsColumnIndex: -1
                    }}
                    actions={getActions()}
                    data={state.listData.rows}
                />
            </Card>
        </>
    );
};

export const MemoizedList = React.memo(HerbsList);
