import {VisitActions} from "../actions/VisitActions";
import {GetVisitDetailsResponse} from "../api/response/GetVisitDetailsResponse";
import {BasicData} from "../types/BasicData";
import {Prescription} from "../../prescription/api/response/Prescription";
import {VisitImages} from "../types/VisitImages";
import {Pulse} from "../types/Pulse";
import dayjs from "dayjs";
import {properties} from "../../common/constants/properties";

type VisitData = {
    basicData: BasicData;
    prescriptions: Prescription[];
    visitImages: VisitImages;
}

const emptyVisitData: VisitData = {
    basicData: {
        date: dayjs(),
        syndrome: "",
        diagnosis: "",
        ailments: "",
        tests: "",
        note: "",
        leftCun: "",
        rightCun: "",
        leftGuan: "",
        rightGuan: "",
        leftChi: "",
        rightChi: "",
    },
    prescriptions: [],
    visitImages: {
        newImages: [],
        existingImageIds: []
    }
}

const mapPulse = (visitData: VisitData): Pulse => {
    return {
        note: visitData.basicData.note,
        leftChi: visitData.basicData.leftChi,
        leftCun: visitData.basicData.leftCun,
        leftGuan: visitData.basicData.leftGuan,
        rightChi: visitData.basicData.rightChi,
        rightCun: visitData.basicData.rightCun,
        rightGuan: visitData.basicData.rightGuan
    }
}

const mapVisitData = (response: GetVisitDetailsResponse): VisitData => {
    return {
        basicData: {
            date: response.date,
            syndrome: response.syndrome,
            diagnosis: response.diagnosis,
            ailments: response.ailments,
            tests: response.tests,
            note: response.pulse.note,
            leftCun: response.pulse.leftCun,
            rightCun: response.pulse.rightCun,
            leftGuan: response.pulse.leftGuan,
            rightGuan: response.pulse.rightGuan,
            leftChi: response.pulse.leftChi,
            rightChi: response.pulse.rightChi,
        },
        prescriptions: response.prescriptions,
        visitImages: {
            existingImageIds: response.imageIds,
            newImages: []
        }
    }
}

const visitReducer = (state: VisitData, action: VisitActions): VisitData => {
    switch (action.type) {
        case "changeBasicData":
            return {
                ...state,
                basicData: {...state.basicData, [action.value.fieldName]: action.value.fieldValue}
            }
        case "addPrescriptionItem":
            return {
                ...state,
                prescriptions: [action.value, ...state.prescriptions],
            }
        case "removePrescriptionItem":
            return {
                ...state,
                prescriptions: state.prescriptions.filter(item => item.id !== action.value.id),
            }
        case "setVisitData":
            return mapVisitData(action.value);
        case "addNewImages":
            return {
                ...state,
                visitImages: {
                    ...state.visitImages,
                    newImages: [...state.visitImages.newImages, ...action.value]
                }
            }
        case "removeNewImage":
            return {
                ...state,
                visitImages: {
                    ...state.visitImages,
                    newImages: state.visitImages.newImages.filter((item, index) => {
                        return index !== action.value.index
                    }),
                }
            }
        case "removeExistingImage":
            return {
                ...state,
                visitImages: {
                    ...state.visitImages,
                    existingImageIds: state.visitImages.existingImageIds.filter(id => id !== action.value.imageId),
                }
            }
        case "updateVisitDate":
            return {
                ...state,
                basicData: {...state.basicData, [properties.visitDate.key]: action.value}
            }
    }
}

export {visitReducer, mapVisitData, emptyVisitData, mapPulse};
export type { VisitData };
