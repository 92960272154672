import {makeStyles} from "tss-react/mui";

export const usePatientCardViewStyle = makeStyles()((theme) => ({
    body: {
        display: "flex",
        padding: theme.spacing(1),
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: 25,
        marginTop: theme.spacing(2),
    },
}))

export const usePatientCardBasicDataViewStyle = makeStyles()((theme) => ({
    root: {
        padding: theme.spacing(1),
        borderRadius: 25,
    },
    firstRow: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    secondRow: {
        display: "flex",
        justifyContent: "flex-start",
        gap: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    iconTypography: {
        display: "flex",
        alignItems: 'center',
        gap: theme.spacing(1),
        flexWrap: 'wrap',
    }
}))
