import React, {useState} from "react";
import DropZone from "./DropZone";
import {makeStyles} from "tss-react/mui";
import ThumbnailImage from "./ThumbnailImage";

const useStyles = makeStyles()((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
    },
    thumbnailContainer: {
        marginLeft: theme.spacing(4),
    }
}))

type Props = {
    herbId: number;
    setThumbnail: (file: File) => void;
}

export default function ThumbnailUploader({herbId, setThumbnail}: Props) {
    const [imageFile, setImageFile] = useState(null);
    const {classes} = useStyles();

    const onThumbnailSelected = (files: File[]): void => {
        let filesArray = Array.from(files);
        let file = filesArray[0];

        setImageFile((file));
        setThumbnail(file);
    }

    return (
        <div className={classes.root}>
            <DropZone onFilesSelected={onThumbnailSelected}
                      isMultiple={false}/>
            <div className={classes.thumbnailContainer}>
                <ThumbnailImage imageFile={imageFile}
                                herbId={herbId}
                />
            </div>
        </div>
    );
}
