import React, {useCallback, useContext, useEffect, useReducer} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Api, ApiContext} from "../../../common/api";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import Visit from "./Visit";
import {Prescription} from "../../../prescription/api/response/Prescription";
import {PutVisitDetailsRequest} from "../../api/request/PutVisitDetailsRequest";
import {GetVisitDetailsResponse} from "../../api/response/GetVisitDetailsResponse";
import {PutVisitImagesRequest} from "../../../images/api/request/PutVisitImagesRequest";
import {PutPrescriptionRequest} from "../../../prescription/api/request/PutPrescriptionRequest";
import {emptyVisitData, mapPulse, VisitData, visitReducer} from "../../reducers/visitReducer";

const mapPutVisitDetailsRequest = (visitData: VisitData): PutVisitDetailsRequest => {
    const prescriptionRequests: PutPrescriptionRequest[] =
        visitData.prescriptions.map((prescription: Prescription) => {
            return {
                herbProcessing: prescription.herbProcessing,
                id: prescription.id,
                medicineList: prescription.medicineList,
                nameSurname: prescription.nameSurname,
            }
        })

    return {
        date: visitData.basicData.date,
        syndrome: visitData.basicData.syndrome,
        diagnosis: visitData.basicData.diagnosis,
        ailments: visitData.basicData.ailments,
        tests: visitData.basicData.tests,
        pulse: mapPulse(visitData),
        prescriptions: prescriptionRequests,
    }
}

const mapPutVisitImagesRequest = (visitData: VisitData): PutVisitImagesRequest => {
    let newImageFiles;

    if (visitData.visitImages.newImages) {
        newImageFiles = visitData.visitImages.newImages.map(newImage => newImage.file);
    }

    return {
        existingImageIds: visitData.visitImages.existingImageIds,
        newImageFiles: newImageFiles,
    };
}

export default function EditVisit() {
    const {patientApi, imagesApi} = useContext<Api>(ApiContext);
    const {patientId, visitId} = useParams<{ "patientId", "visitId" }>();
    const [visitData, dispatchVisitData] = useReducer(visitReducer, emptyVisitData);
    const navigate = useNavigate();

    useEffect(() => {
        if (visitId) {
            patientApi.getVisitDetails(visitId)
                .then((resp: AxiosResponse<GetVisitDetailsResponse>) => {
                    dispatchVisitData({type: "setVisitData", value: resp.data});
                }).catch(() => {
            })
        }
    }, [visitId])

    const cancel = useCallback(async () => {
        navigate(-1);
    }, [])

    const update = async () => {
        const putImagesRequest = mapPutVisitImagesRequest(visitData);
        imagesApi.putVisitImages(visitId, putImagesRequest)
            .then(() => {
                updateVisitDetails();
            }).catch(() => {
        })
    };

    const updateVisitDetails = () => {
        patientApi.putVisitDetails(visitId, mapPutVisitDetailsRequest(visitData))
            .then(() => {
                toast.success("Zmodyfikowano wizytę");
                navigate(-1);
            }).catch(() => {
        });
    }

    return (
        <Visit visitData={visitData}
               handleCancelClick={cancel}
               handleSaveClick={update}
               visitDispatcher={dispatchVisitData}/>
    )
}
