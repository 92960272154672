import React, {useCallback, useState} from "react";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Grid
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PostSearchHerbsRequest} from "../api/request/PostSearchHerbsRequest";
import {Option} from "../api/Option";
import HerbsFilterChipsField from "./HerbsFilterChipsField";
import {makeStyles} from "tss-react/mui";
import HerbNameSearchTextField from "./HerbNameSearchTextField";
import {OptionKey} from "../api/OptionKey";
import {properties} from "../../common/constants/properties";

const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
        '& .MuiPaper-root': {
            borderRadius: 25,
        }
    },
    accordionSummary: {
        "& .Mui-focusVisible": {
            backgroundColor: "transparent",
        },
    },
}));

const initialFilter: PostSearchHerbsRequest = {
    name: '',
    nameLatin: '',
    herbActionCenters: '',
    directionality: '',
    pregnancy: '',
    herbGroups: '',
};

type Props = {
    onFilterChanged: (newFilter: PostSearchHerbsRequest) => void;
};

export default function HerbsBrowserFilter({onFilterChanged}: Props) {
    const {classes} = useStyles();
    const [filterValues, setFilterValues] = useState(initialFilter);

    const onFilterValueChange = useCallback((name: string, value: string | Option): void => {
        if (value) {
            value = typeof value === "string" ? value : value.label;
        } else {
            value = '';
        }

        setFilterValues((prevState) => {
            return {...prevState, [name]: value}
        });
    }, []);

    const onFilterClicked = () => {
        onFilterChanged(filterValues);
    }

    const onClearClicked = () => {
        let emptyFilter = {
            name: '',
            nameLatin: '',
            herbActionCenters: '',
            directionality: '',
            pregnancy: '',
            herbGroups: '',
        }

        onFilterChanged(emptyFilter);
        setFilterValues(emptyFilter);
    }

    return (
        <Box className={classes.root}>
            <Accordion>
                <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon/>}
                    id={"filter-header"}
                >
                    <HerbNameSearchTextField onFilterChange={onFilterChanged}/>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container
                          spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <HerbsFilterChipsField optionKey={OptionKey.GROUP}
                                                   property={properties.herbGroups}
                                                   value={{id: 1, label: filterValues.herbGroups}}
                                                   onChange={onFilterValueChange}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <HerbsFilterChipsField optionKey={OptionKey.ACTION_CENTER}
                                                   property={properties.herbActionCenters}
                                                   value={{id: 1, label: filterValues.herbActionCenters}}
                                                   onChange={onFilterValueChange}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <HerbsFilterChipsField property={properties.herbPregnancy}
                                                   value={{id: 1, label: filterValues.pregnancy}}
                                                   onChange={onFilterValueChange}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <HerbsFilterChipsField optionKey={OptionKey.HERB_DIRECTIONALITY}
                                                   property={properties.herbDirectionality}
                                                   value={{id: 1, label: filterValues.directionality}}
                                                   onChange={onFilterValueChange}/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <Divider/>
                <AccordionActions>
                    <Button size="small"
                            onClick={onClearClicked}>
                        Wyczyść
                    </Button>
                    <Button size="small"
                            color="primary"
                            onClick={onFilterClicked}>
                        Filtruj
                    </Button>
                </AccordionActions>
            </Accordion>
        </Box>
    )
}
