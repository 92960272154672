import React, {memo, useContext, useState} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import {TextField} from "@mui/material";
import {Option} from "../api/Option";
import {AxiosResponse} from "axios";
import {Api, ApiContext} from "../../common/api";
import {OptionKey} from "../api/OptionKey";
import {Property} from "../../common/constants/Property";

type Props = {
    property: Property;
    optionKey?: OptionKey;
    value: Option;
    onChange: (name: string, value: string | Option) => void;
};

function HerbsFilterChipsField({optionKey, property, value, onChange}: Props) {
    const {herbsApi} = useContext<Api>(ApiContext);
    const [options, setOptions] = useState([] as Option[]);

    const onInputChange = (searchString: string): void => {
        if (optionKey) {
            herbsApi.filterOptions({optionKey: optionKey, searchString: searchString})
                .then((res: AxiosResponse<Option[]>) => {
                    setOptions(res.data);
                }).catch(() => {
            });
        }
    };

    return (
        <Autocomplete freeSolo
                      id={property.key + 'Filter'}
                      value={value}
                      options={options}
                      getOptionLabel={option => typeof option === 'string' ? option : option.label}
                      onInputChange={(event, value) => {
                          onInputChange(value);
                          onChange(property.key, value);
                      }}
                      renderInput={(params) =>
                          (<TextField {...params}
                                      id={property.key + 'Filter'}
                                      variant="outlined"
                                      name={property.key + 'Filter'}
                                      label={property.label}
                              />
                          )}/>
    )
}

export default memo(HerbsFilterChipsField);
