import React, {useContext, useEffect, useReducer, useRef} from "react";
import {Card, CardActions, IconButton, InputBase, Tooltip, Typography} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import MedicineList from "./MedicineList";
import {Add, Delete, Edit, Search} from "@mui/icons-material";
import {copyObject, parseDate} from "../../common/utils/utils";
import {Medicine} from "../api/response/Medicine";
import {Prescription} from "../api/response/Prescription";
import {makeStyles} from "tss-react/mui";
import {PrescriptionActions} from "../actions/PrescriptionActions";
import {PrescriptionCardMode} from "../types/PrescriptionCardMode";
import {useParams} from "react-router-dom";
import {Api, ApiContext} from "../../common/api";

const useStyles = makeStyles()((theme) => ({
    cardContainer: {
        textAlign: 'left',
        marginBottom: theme.spacing(2),
    },
    card: {
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: 25
    },
    inputRoot: {
        "& root": {
            width: "100%",
            margin: theme.spacing(1.5, 2, 2, 2),
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.typography.h4.fontFamily,
            lineHeight: theme.typography.h4.lineHeight,
            letterSpacing: theme.typography.h4.letterSpacing,
            fontWeight: theme.typography.h4.fontWeight,
        },
    },
    inputBaseInput: {
        "& input": {
            width: "100%",
            fontWeight: 500,
            fontSize: "1rem",
            padding: 0,
            lineHeight: theme.spacing(0.18),
            verticalAlign: "middle",
            color: theme.palette.text.primary
        }
    },
    inputMultiline: {
        "& inputMultiline": {
            width: "100%",
            fontWeight: 500,
            fontSize: "1rem",
            padding: 0,
            lineHeight: theme.spacing(0.18),
            verticalAlign: "middle",
            color: theme.palette.text.primary
        }
    },
    cardContent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& button': {
            marginLeft: theme.spacing(2),
        }
    },
    date: {
        fontStyle: 'italic',
    }
}))

const emptyPrescriptionData: Prescription = {
    herbProcessing: '',
    nameSurname: '',
    medicineList: [],
}

type Mode = {
    currentMode: PrescriptionCardMode;
    previousMode: PrescriptionCardMode
}

type Props = {
    prescriptionData?: Prescription;
    cardDataChangeHandler: (prescriptionData: Prescription) => void;
    cardDeleteHandler: (prescriptionId: number) => void;
    cardSaveHandler: (prescriptionData: Prescription) => void;
    openHerbDialogHandler: () => void;
};

type State = {
    prescriptionData: Prescription;
    originalPrescriptionData: Prescription;
    mode: Mode;
};

const prescriptionCardReducer = (state: State, action: PrescriptionActions): State => {
    switch (action.type) {
        case "changePrescriptionData":
            state.prescriptionData[action.value.propName] = action.value.value;
            return {...state, prescriptionData: state.prescriptionData};
        case "handleAddMedicine":
            state.prescriptionData.medicineList.push(action.value);
            return {...state, prescriptionData: state.prescriptionData};
        case "changeMedicineData":
            let newMedicineList = [
                ...state.prescriptionData.medicineList.slice(0, action.value.index),
                {
                    ...state.prescriptionData.medicineList[action.value.index],
                    [action.value.propName]: action.value.propValue
                },
                ...state.prescriptionData.medicineList.slice(++action.value.index)];

            state.prescriptionData.medicineList = newMedicineList;
            return {...state, prescriptionData: state.prescriptionData};
        case "handleCancel":
            if (state.mode.currentMode === PrescriptionCardMode.EDIT) {
                return {
                    ...state,
                    prescriptionData: state.originalPrescriptionData,
                    mode: {
                        currentMode: PrescriptionCardMode.LIST_ITEM,
                        previousMode: PrescriptionCardMode.EDIT
                    }
                };
            } else {
                return {
                    ...state,
                    prescriptionData: copyObject(emptyPrescriptionData),
                    mode: {
                        currentMode: PrescriptionCardMode.EMPTY,
                        previousMode: PrescriptionCardMode.EDIT
                    }
                }
            }
        case "handleSave":
            if (state.mode.currentMode === PrescriptionCardMode.EMPTY) {
                return {
                    ...state,
                    prescriptionData: copyObject(emptyPrescriptionData),
                    mode: {
                        currentMode: PrescriptionCardMode.EMPTY,
                        previousMode: PrescriptionCardMode.EDIT
                    }
                };
            } else {
                return {
                    ...state,
                    mode: {
                        currentMode: PrescriptionCardMode.LIST_ITEM,
                        previousMode: PrescriptionCardMode.LIST_ITEM
                    }
                }
            }
        case "handleEdit":
            return {
                ...state,
                mode: {
                    currentMode: PrescriptionCardMode.EDIT,
                    previousMode: PrescriptionCardMode.LIST_ITEM
                }
            };
        case "handleDeleteMedicine":
            state.prescriptionData.medicineList.splice(action.value, 1);
            return {...state, prescriptionData: state.prescriptionData};
    }
}

export default function PrescriptionCard(props: Props) {
    const {classes} = useStyles();
    const [state, dispatchState] = useReducer(prescriptionCardReducer, getDefaultState());
    const {patientId} = useParams<"patientId">();
    const {patientApi} = useContext<Api>(ApiContext)
    const addButtonRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (patientId && state.mode.currentMode == PrescriptionCardMode.EMPTY) {
            patientApi.getPatientName(Number(patientId))
                .then((response) => {
                    dispatchState({
                        type: 'changePrescriptionData',
                        value: {propName: 'nameSurname', value: response.data.name}
                    })
                })
        }
    }, [state.mode, patientId])

    function getDefaultState(): State {
        if (props.prescriptionData) {
            const mode: Mode = {
                currentMode: PrescriptionCardMode.LIST_ITEM,
                previousMode: null
            };
            return {
                prescriptionData: props.prescriptionData,
                originalPrescriptionData: props.prescriptionData,
                mode: mode
            }
        } else {
            const mode: Mode = {
                currentMode: PrescriptionCardMode.EMPTY,
                previousMode: null
            };
            return {
                prescriptionData: copyObject(emptyPrescriptionData),
                originalPrescriptionData: copyObject(emptyPrescriptionData),
                mode: mode
            }
        }
    }

    const onAddNewClick = () => {
        handleMedicineAdded({
            id: ``,
            name: '',
            boilingTime: '',
            dose: ''
        } as Medicine)
        addButtonRef?.current.scrollIntoView();
    }

    const determineReadOnly = () => {
        return state.mode.currentMode === PrescriptionCardMode.LIST_ITEM;
    }

    const extractMedicineList = () => {
        return state.prescriptionData ? state.prescriptionData.medicineList : [] as Medicine[];
    }

    const onEditClick = () => {
        dispatchState({type: 'handleEdit'})
    }

    const onDeleteClick = () => {
        props.cardDeleteHandler(props.prescriptionData.id);
    }

    const onSaveClick = () => {
        if (state.mode.currentMode === PrescriptionCardMode.EMPTY) {
            props.cardSaveHandler(state.prescriptionData);
        } else {
            if (props.cardDataChangeHandler) {
                props.cardDataChangeHandler(state.prescriptionData);
            }
        }

        dispatchState({type: 'handleSave'});
    }

    const onCancelClick = () => {
        dispatchState({type: 'handleCancel'})
    }

    const handleMedicineChanged = (index: number, propName: string, propValue: string) => {
        dispatchState({
            type: 'changeMedicineData',
            value: {
                index: index,
                propName: propName,
                propValue: propValue
            }
        });
    }

    const handleMedicineAdded = (medicineData: Medicine) => {
        dispatchState({type: 'handleAddMedicine', value: medicineData});
    }

    const handleDeleteMedicine = (index: number) => {
        dispatchState({type: 'handleDeleteMedicine', value: index});
    }

    const CardHead = () => {
        return (
            <>
                <InputBase
                    value={state.prescriptionData.nameSurname}
                    fullWidth
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputRoot
                    }}
                    placeholder={"Imię i nazwisko"}
                    inputProps={{readOnly: determineReadOnly(), disabled: determineReadOnly()}}
                    onChange={(e) => {
                        dispatchState({
                            type: 'changePrescriptionData',
                            value: {propName: 'nameSurname', value: e.target.value}
                        })
                    }}
                />
            </>
        )
    }

    const CardBody = () => {
        return (
            <div>
                <MedicineList
                    medicineChangeHandler={handleMedicineChanged}
                    mode={state.mode.currentMode}
                    medicineList={extractMedicineList()}
                    deleteMedicineHandler={handleDeleteMedicine}
                />
                <Divider/>
                <InputBase
                    value={state.prescriptionData.herbProcessing}
                    fullWidth
                    classes={{
                        root: classes.inputRoot,
                        inputMultiline: classes.inputMultiline
                    }}
                    placeholder={"Sposób obróbki ziół"}
                    multiline
                    inputProps={{readOnly: determineReadOnly(), disabled: determineReadOnly()}}
                    onChange={(e) => {
                        dispatchState({
                            type: 'changePrescriptionData',
                            value: {propName: 'herbProcessing', value: e.target.value}
                        })
                    }}
                />
            </div>
        );
    }

    const CardFooter = () => {
        switch (state.mode.currentMode) {
            case PrescriptionCardMode.EMPTY:
                return (
                    <div className={classes.actions}>
                        <div>
                            <Tooltip title={"Baza ziół"}>
                                <IconButton onClick={() => props.openHerbDialogHandler()} size="large">
                                    <Search/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Dodaj zioło"} ref={addButtonRef}>
                                <IconButton onClick={onAddNewClick} size="large">
                                    <Add/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div>
                            <Button size={"small"}
                                    onClick={() => onCancelClick()}>
                                Anuluj
                            </Button>
                            <Button size={"small"}
                                    color={"primary"}
                                    onClick={() => onSaveClick()}>
                                Zapisz
                            </Button>
                        </div>
                    </div>
                );
            case PrescriptionCardMode.EDIT:
                return (
                    <div className={classes.actions}>
                        <div>
                            <Tooltip title={"Usuń receptę"}>
                                <IconButton onClick={() => onDeleteClick()} size="large">
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Baza ziół"}>
                                <IconButton onClick={() => props.openHerbDialogHandler()} size="large">
                                    <Search/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Dodaj zioło"} ref={addButtonRef}>
                                <IconButton onClick={onAddNewClick} size="large">
                                    <Add/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div>
                            <Button size={"small"}
                                    onClick={() => onCancelClick()}>
                                Anuluj
                            </Button>
                            <Button size={"small"}
                                    color={"primary"}
                                    onClick={() => onSaveClick()}>
                                Zapisz
                            </Button>
                        </div>
                    </div>
                );
            case PrescriptionCardMode.LIST_ITEM:
                return <>
                    <Tooltip title={"Usuń receptę"}>
                        <IconButton onClick={() => onDeleteClick()} size="large">
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Edytuj receptę"}>
                        <IconButton onClick={() => onEditClick()} size="large">
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                </>;
        }
    }

    return (
        <div className={classes.cardContainer}>
            <Typography color={'textSecondary'} className={classes.date}>
                {parseDate(state.prescriptionData.created)}
            </Typography>
            <Card elevation={2} className={classes.card}>
                {CardHead()}
                <Divider light/>
                <CardContent>
                    {CardBody()}
                </CardContent>
                <Divider light/>
                <CardActions>
                    {CardFooter()}
                </CardActions>
            </Card>
        </div>
    );
};

