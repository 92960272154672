import React, {useCallback, useState} from "react";
import {Dialog, DialogContent} from "@mui/material";
import {PostSearchHerbsRequest} from "../api/request/PostSearchHerbsRequest";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import HerbsBrowserFilter from "./HerbsBrowserFilter";
import HerbsList from "./HerbsList";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    herbContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
        borderRadius: 25,
    },
    dialogTitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dialogContent: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const defaultFilterDto: PostSearchHerbsRequest = {
    directionality: "",
    herbActionCenters: "",
    herbGroups: "",
    name: "",
    nameLatin: "",
    pregnancy: ""
};

type Props = {
    isOpen: boolean;
    handleClose: () => void
}

export default function HerbsBrowserDialog(props: Props) {
    const {classes} = useStyles();
    const [filter, setFilter] = useState(defaultFilterDto);

    const onFilterChange = useCallback((newFilter: PostSearchHerbsRequest): void => {
        setFilter((prevState => {
            return {...prevState, ...newFilter}
        }));
    }, []);

    return (
        <Dialog fullScreen
                open={props.isOpen}
                onClose={props.handleClose}
                scroll={"paper"}>
            <AppBar position={"static"}>
                <Toolbar>
                    <IconButton onClick={props.handleClose} edge={"start"} color={"inherit"} size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant={"h6"} className={classes.dialogTitle}>
                        Baza ziół
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                <HerbsBrowserFilter onFilterChanged={onFilterChange}/>
                <HerbsList filter={filter} isDialog={true}/>
            </DialogContent>
        </Dialog>
    );
}
