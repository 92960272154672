import React from 'react';
import {Button} from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {HerbData} from "../api/HerbData";
import HerbForm from "./HerbForm";
import ThumbnailUploader from "../../images/components/ThumbnailUploader";
import {makeStyles} from "tss-react/mui";
import {HerbActions} from "../actions/HerbActions";
import useConfirm from "../../common/hooks/useConfirm";

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: 'auto',
        height: '100%',
    },
    herbBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    formContainer: {
        maxWidth: '100%',
        flexGrow: 1,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    thumbnailContainer: {
        maxWidth: '100%',
        flexGrow: 1,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    textField: {
        width: '100%',
        margin: theme.spacing(1),
    },
    buttonContainer: {
        display: "flex",
        gap: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    submitButton: {
        width: '100%'
    }
}));

type Props = {
    herbData: HerbData;
    handleCancelClick: () => void;
    handleSaveClick: () => void;
    herbDispatcher: (action: HerbActions) => void;
    setThumbnail: (file: File) => void;
}

export default function Herb(props: Props) {
    const {classes} = useStyles();
    const {withConfirm} = useConfirm();

    const {
        herbData,
        handleCancelClick,
        handleSaveClick,
        herbDispatcher,
        setThumbnail
    } = props;

    const onSubmit = async (event: any) => {
        await withConfirm(handleSaveClick, "Czy na pewno chcesz zapisać?");
        event.preventDefault();
    };

    const onCancel = async (event: any) => {
        await withConfirm(handleCancelClick, "Czy na pewno chcesz anulować?");
        event.preventDefault();
    }

    return (
        <div className={classes.root}>
            <Box className={classes.herbBox}>
                <Paper className={classes.thumbnailContainer}
                       elevation={3}>
                    <ThumbnailUploader herbId={herbData.id}
                                       setThumbnail={setThumbnail}/>
                </Paper>
                <Paper className={classes.formContainer}
                       elevation={3}>
                    <HerbForm herbData={herbData}
                              dispatchHerbData={herbDispatcher}/>
                </Paper>
            </Box>
            <Box className={classes.buttonContainer}>
                <Button className={classes.submitButton}
                        variant="contained"
                        color="primary"
                        onClick={onCancel}>
                    Anuluj
                </Button>
                <Button className={classes.submitButton}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}>
                    Zapisz
                </Button>
            </Box>
        </div>
    );
}
